// Header.js
import React, {useContext, useEffect, useState, useRef} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import NotificationsBoxComponent from '../notifications-box/notifications-box.component';
import Search from '../search-bar/search-bar';
import { toast } from 'react-toastify';
import { SocketContext } from '../../context/socketContext';
import { ApiContext } from '../../context/apiContext';
import {
    HeaderContainer,
    Greeting,
    UserSection,
    Icon,
    // ProfilePicture,
    Blip
} from './header.styles';
import { ReactComponent as Notifications } from '../../assets/notification.svg'
import { ReactComponent as Settings } from '../../assets/setting.svg'
import YearFilter from '../year-filter/year-filter';

const Header = ({name,image}) => {

    const notificationRef = useRef(null);
    const [notificationCount, setNotificationCount] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);
    const { subscribeToNewNotifications } = useContext(SocketContext);
    const api = useContext(ApiContext)
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await api.get(`/api/notification/count`)
                setNotificationCount(data.count)
            }catch(error){
                // toast.error(`There was an error loading notifications: ${error.message}`);
            }
        }

        fetchData()
    },[])

    useEffect(() => {
        // Function to detect outside clicks
        function handleClickOutside(event) {
            if (notificationRef.current && !notificationRef.current.contains(event.target)) {
                setShowNotifications(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [notificationRef]);

    useEffect(() => {
        // Subscribe to new chat messages
        const unsubscribe = subscribeToNewNotifications((newNotifications) => {
            setNotificationCount(prev => prev + 1);
            toast(newNotifications.content, {
                onClick: () => navigate(newNotifications.url)
            })
        });

        return () => unsubscribe(); // Cleanup the subscription on unmount
    }, [ subscribeToNewNotifications]);

    const toggleNotifications = () => {
        setShowNotifications(prev => !prev)
        setNotificationCount(0);
    }

    return (
        <HeaderContainer id='header'>
            <Greeting>
                Hello {name} <span className="emoji" role='img' aria-label=''>👋</span>
            </Greeting>
            <UserSection>
               <Search/>
               {
                    !/insights|messaging|properties/.test(location.pathname) && <YearFilter/>
               }
                <Icon ref = {notificationRef} onClick = {toggleNotifications}>
                    <Notifications/>
                    {
                        notificationCount > 0 &&
                        <Blip>{notificationCount}</Blip>
                    }
                    {
                        showNotifications &&
                        <NotificationsBoxComponent/>
                    }
                </Icon>
                <Icon>
                    <Settings onClick = {() => navigate(`/settings`)} />
                </Icon>
                {/* <ProfilePicture src={image} alt="Profile" /> */}
            </UserSection>
        </HeaderContainer>
    );
};

export default Header;
