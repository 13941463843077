import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: ${props =>  `calc( 100% - ${props.height}px )`};
    width: -webkit-fill-available;
    padding-top: ${props => props.height === 60 && '20px'};
`;

export const Heading = styled.div`
    color: var(--Greyish-Black, #323232);
    leading-trim: both;
    text-edge: cap;

    /* Title/Extra Small */
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const FlexDivColumn = styled.div`
    display: flex;
    gap: 15px;
    flex-direction: column;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0px 30px 20px;
    overflow: auto;
    height: calc( 100% - 60px );
`;


export const ButtonDiv = styled.div`
    display: flex;
    padding: 10px 30px 10px;
    justify-content: flex-end;
    gap: 20px;
    bottom: 0;
    // position: absolute;
    border-top: 1px solid #fafafa;
    width: -webkit-fill-available;
`;

export const NextButton = styled.button`
    background-color: #064274    ;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 12px;
    width: 250px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;

    &:hover{ 
        background-color: #002456;
        transition: background-color 0.2s;
    }
`;

export const PreviousButton = styled(NextButton)`
    background-color: #F7F7F7;
    color: #323232;
    font-weight: 400;
    // cursor: pointer;
    
    &:hover{ 
        background-color: #dedede80;
        transition: background-color 0.2s;
    }
`;

export const FormTextArea = styled.textarea`
  padding: 10px;
  // margin-bottom: 10px;
  border: 1px solid var(--F1, #F1F1F1);
  border-radius: 7px;
  height: 100px;
  font-family: Avenir;
`;

export const TeammateBox = styled.div`
    // border: 1px solid #f3f3f3;
    width: fit-content;
    border-radius: 10px;
    // padding: 0px 15px;
    width: -webkit-fill-available;
`;

export const TeammateName = styled.div`
    color: var(--Greyish-Black, #323232);
    /* Body/Large */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;


export const TeammateContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #F7F7F7;
    padding: 10px 0px;
    align-items: center;

    cursor: pointer;

    &:nth-child(1){
        padding-top: 0px !important;
    }

    &:nth-last-child(1){
        border-bottom: unset;
    }
`;

export const TeammateDetails = styled.div`
    display: flex;
    justify-content: space-between;
    // min-width: 250px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    input {
        margin: unset!important;
    }
`;

export const SubscriptText = styled.div`
    color: var(--Light-Grey, #A4A4A4);
    leading-trim: both;
    text-edge: cap;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;