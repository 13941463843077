import styled from "styled-components";

export const Container =  styled.div`
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    overflow: auto;
    height: -webkit-fill-available;
`;

export const ButtonDiv = styled.div`
    display: flex;
    padding: 10px 30px 10px;
    justify-content: flex-end;
    gap: 20px;
    bottom: 0;
    // position: absolute;
    border-top: 1px solid #fafafa;
    width: -webkit-fill-available;
`;

export const NextButton = styled.button`
    background-color: #064274    ;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 12px;
    width: 250px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;

    &:hover{ 
        background-color: #002456;
        transition: background-color 0.2s;
    }
`;

export const PreviousButton = styled(NextButton)`
    background-color: #F7F7F7;
    color: #323232;
    font-weight: 400;
    // cursor: pointer;
    
    &:hover{ 
        background-color: #dedede80;
        transition: background-color 0.2s;
    }
`;