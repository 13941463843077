import React, { useState } from "react";
import ApplicationSummary from "../../application-summary/application-summary";
import { 
    SectionTitle,
    ApplicantContainer,
    NextButton,
    PreviousButton,
    GapDiv,
    AttachmentBox, PreviewContainer, PDFIconWrapper, FileName,
} from "../shared-styles";
import { ReactComponent as Document } from '../../../assets/document-upload.svg'


const ReviewApplication = ({property, applicationData, setIsFocus, setFinalId}) => {

    // const {months, days} = dateDiff(applicationData.requestedMoveIn, applicationData.requestedMoveOut)
    const [isSubmitting, setIsSubmitting] = useState(false); // Manage button disabled state

    const handleSubmit = async () => {
        setIsSubmitting(true);
        // console.log(applicationData)
        // Extract the subdomain from the current URL
        const { hostname } = window.location;
        const subdomain = hostname.split('.')[0];

        const apiEndpoint = `/api/application/new?company=${subdomain}`; // Replace with your actual endpoint
        const { applicationPackage, ...payloadWithoutPackage } = applicationData;
        // setIsFocus('Submitted'); // Update state upon successful API call
        try {
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payloadWithoutPackage), // Convert the JavaScript object to a JSON string
            });

            const data = await response.json();
        
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }      

            const applicationId = data.id;
            if (applicationData.isDirectUpload && applicationPackage) {
                // Create a new FormData object to hold the file and applicationId
                const formData = new FormData();
                const subdomain = hostname.split('.')[0];
                formData.append('applicationId', applicationId);
                formData.append('applicationPackage', applicationPackage);
                formData.append('company', subdomain);
    
                // Send the second API request to upload the package
                const uploadEndpoint = '/api/application/upload_package'; // Replace with your actual endpoint
                const uploadResponse = await fetch(uploadEndpoint, {
                    method: 'POST',
                    body: formData, // Send the form data with file
                });
    
                if (!uploadResponse.ok) {
                    throw new Error(`Upload failed! status: ${uploadResponse.status}`);
                }
            }

            setIsFocus('Submitted'); // Update state upon successful API call
            setFinalId(applicationId)
        } catch (error) {
            alert('There was an issue submitting your request. Please email us at support@avaasa.us for assistance.');
            setIsSubmitting(false); // Re-enable the button if there is an error
        }

    };

    return (
        <>
            <ApplicantContainer>
                <SectionTitle>Review Submission</SectionTitle>
                {
                    applicationData.isDirectUpload ?
                    <div>
                        The application package-
                        <AttachmentBox 
                            style = {{marginTop:'10px', cursor: 'pointer'}} 
                            onClick={() => window.open(URL.createObjectURL(applicationData.applicationPackage), '_blank')}
                        >
                            <PreviewContainer>
                                <PDFIconWrapper>
                                    <Document /> {/* Use a PDF icon or SVG component */}
                                </PDFIconWrapper>
                                <FileName>{applicationData.applicationPackage.name}</FileName>
                            </PreviewContainer>
                        </AttachmentBox>
                    </div>
                    :
                    <div>
                        The applicants and guarantors will receive a pre-populated Greater Boston rental application form with the details provided below. 
                        Please thoroughly review it before submitting the request.
                    </div>
                }
                <ApplicationSummary
                    property = {property}
                    applicationData = {applicationData}
                    offerMoveinDeposits = {applicationData.moveinDeposits}
                />
                <GapDiv>
                    <PreviousButton onClick={() => setIsFocus(applicationData.isDirectUpload ? 'Package':'Applicants')}>Previous</PreviousButton>
                    <NextButton disabled={isSubmitting} onClick={() => handleSubmit()}>{isSubmitting ? 'Submitting...' : 'Submit Application Request'}</NextButton>
                </GapDiv>
            </ApplicantContainer>
            
        </>
    )
}

export default ReviewApplication