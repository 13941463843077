import React, { useState, useContext } from "react";
import Package from "../../application-modality/review-modality/package/package";
import AdditionalDocs from "../../application-modality/additional-docs/additional-docs";
import RejectionModal from "../../reject-modal/reject-modal";
import { toast } from "react-toastify";
import { UserContext } from "../../../context/userContext";
import {
    Container, NextButton, PreviousButton, ButtonDiv
} from './renewal-package.styles'

const RenewalPackage = ({focusRenewal, setFocusRenewal, showRenewalModal}) => {
    const user = useContext(UserContext);
    const [activeTab, setActiveTab] = useState('Review');
    const [rejectModal, setRejectModal] = useState(false);

    const updateApplication = (updateData) => {
        setFocusRenewal(prev => ({
            ...prev,
            applicationStatus: updateData.status,
            leaseStartDate: updateData?.leaseStartDate,
            leaseEndDate: updateData?.leaseEndDate,
            payments: updateData?.payments,
            latestUpdateDate: new Date(),
            latestOfferPrice: updateData?.updatedRent,
            dealId: updateData?.dealId
        }))
        if(rejectModal) setRejectModal(false);
        
        toast.info(`Application status changed to ${updateData.status}`)
    }

    return (
        <>
        {
            activeTab === "Review" &&
            <Container>
                <Package 
                    applicants = {focusRenewal?.applicants} 
                    applicationId = {focusRenewal?.applicationId}
                    setActiveTab = {setActiveTab}
                />
                <ButtonDiv>
                    {
                        user?.userType !== 'view_only' && focusRenewal.applicationStatus !== "Rejected" && focusRenewal.applicationStatus !== "Approved" &&
                        <PreviousButton onClick={() => setRejectModal(true)}>Reject & Request Replacement</PreviousButton>
                    }
                    {
                        user?.userType !== 'view_only' && focusRenewal.applicationStatus !== "Rejected" &&  focusRenewal.applicationStatus !== "Approved" &&
                        <NextButton onClick={() => showRenewalModal(focusRenewal)}>Approve & Continue Renewal</NextButton>
                    }
                </ButtonDiv>
            </Container>
        }
        {
            activeTab === "Additional Docs" &&
            <AdditionalDocs 
                applicants = {focusRenewal?.applicants} 
                applicationId = {focusRenewal?.applicationId}
                setActiveTab = {setActiveTab}
                updateApplication = {updateApplication}
                propsHeight = {60}
            />
        }
        {
            rejectModal &&
            <RejectionModal
                onClose = {() => setRejectModal(false)}
                applicationId = {focusRenewal?.applicationId}
                updateApplication = {updateApplication}
                renewalId = {focusRenewal.renewalId}
            />
        }
        </>   
    )
}

export default RenewalPackage