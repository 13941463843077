import React, { useState, useEffect, useContext, useMemo } from 'react'
import SidePanel from './components/side-panel/side-panel'
import Header from './components/header/header';
import Showings from './pages/showings/showings.pages.jsx';
import AgentPropertyList from './pages/agent-property-list/agent-property-list.pages.jsx';
import AgentViewingForm from './pages/agent-viewing-form/agent-viewing-form.pages.jsx';
import AgentApplicationForm from './pages/agent-application-forms/agent-application-forms.pages.jsx';
import Applications from './pages/applications/applications.pages.jsx';
import Insights from './pages/insights/insights.pages.jsx';
import Deals from './pages/deals/deals.pages.jsx';
import Documents from './pages/documents/documents.jsx';
import Messaging from './pages/messaging/messaging.pages.jsx'
import Properties from './pages/properties/properties.pages.jsx';
import Leases from './pages/leases/leases.pages.jsx';
import Payments from './pages/payments/payments.pages.jsx';
import ShowingUpdate from './pages/agent-showing-update/showing-update.pages.jsx';
import ClientReschedule from './pages/tenant-showing-reschedule/tenant-showing-reschedule.jsx';
import TenantConfirmSlot from './pages/tenant-confirm-slot/tenant-confirm-slot.jsx';
import ApplicantFormPages from './pages/applicant-forms/applicant-forms.pages.jsx';
import Login from './pages/login/login.pages.jsx';
import AgentApplicationDashboard from './pages/agent-application-dashboard/agent-application-dashboard.jsx';
import AgentDashboard from './pages/agent-dashboard/agent-dashboard.pages.jsx';
import Settings from './pages/settings/settings.pages.jsx';
import CreateClient from './pages/create-client/create-client-form.jsx';
import { Routes, Route, useLocation } from "react-router-dom";
import { UserContext } from './context/userContext.js';
import { SocketContext } from './context/socketContext.js';
import { useSubdomain } from './context/subdomainContext.js';
import { ToastContainer } from 'react-toastify';
import { PageLayout, MainContent } from './App.styles.js';
// import ContentLoader from 'react-content-loader'
import 'react-toastify/dist/ReactToastify.css';
import Renewals from './pages/renewals/renewals.pages.jsx';

const App = ( ) => {
    const { isPublicSubdomain } = useSubdomain();
    const [headerHeight, setHeaderHeight] = useState(0);
    const { user, verifyToken, accessToken } = useContext(UserContext);
    const { authSocket, isSocketReady } = useContext(SocketContext);
    const [isLoading, setIsLoading] = useState(true);
    const [hasAuthenticated, setHasAuthenticated] = useState(false);

    const location = useLocation();

    // const getSubdomain = () => {
    //     const host = window.location.hostname;
    //     let parts = host.split('.');
      
    //     // Adjust logic for localhost
    //     if (parts.length >= 3 && parts[0] !== 'www') {
    //         return parts[0]; // Subdomain
    //     } else if (host.includes("localhost") && parts.length === 2) {
    //         return parts[0]; // For localhost subdomain testing
    //     }
    //     return null; // No subdomain or www
    //   };

    const openPaths = ['/property-list', '/showing-update',
    '/showing-request', '/tenant-showing-update', '/tenant-confirm-slot', '/application-request',
    '/application-form', '/application-status', '/agent-dashboard'];


    useEffect(() => {
        const checkUserAuthentication = async () => {
            // Check if the current path is an open path
            const isOpenPath = openPaths.some(openPath => location.pathname.startsWith(openPath));

            if(isOpenPath){
                // setIsLoading(false)
                setIsLoading(false)

            }else {
                verifyToken();
                setIsLoading(false)
            }
            // Note: Adjust setIsLoading(false) according to your state management needs
        };
    
        checkUserAuthentication();
    }, []);

    // Effect to handle socket authentication when socket is ready and accessToken is available
    useEffect(() => {
        const isOpenPath = openPaths.some(openPath => location.pathname.startsWith(openPath));
        // console.log('Authentication Check:', { isSocketReady, accessToken, isOpenPath });
    
        if (isSocketReady && accessToken && !isOpenPath && !hasAuthenticated) {
            // console.log('Authenticating socket...');
            authSocket(null, accessToken);
            setHasAuthenticated(true); // Prevent re-authentication
        }
    }, [isSocketReady, accessToken, hasAuthenticated, authSocket, location.pathname]);
    


    // Check the header height and adjust div accordingly
    useEffect(() => {
        const calculateHeaderHeight = () => {
            const headerElement = document.getElementById('header');
            if (headerElement) {
                setHeaderHeight(headerElement.offsetHeight);
            }
        };

        // Calculate initially and on every window resize
        calculateHeaderHeight();
        window.addEventListener('resize', calculateHeaderHeight);

        // Cleanup listener
        return () => window.removeEventListener('resize', calculateHeaderHeight);
    }, []);

    const DefaultLayout = ({ children }) => (
        <>
            <SidePanel />
            <MainContent>
                <Header {...user} />
                {children}
            </MainContent>
        </>
    );

    const DefaultLayout2 = ({ children }) => (
        <>
            <MainContent>
                <Header {...user} />
                {children}
            </MainContent>
        </>
    );

    if(isLoading){
        return <></>
    }
    else if(isPublicSubdomain){
        return (
            <PageLayout>
                <div id ="modal-root"/>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                    stacked
                />
                <Routes>
                    <Route path="/" element={<AgentPropertyList headerHeight = {headerHeight}/>}/>
                    <Route path="/showing-request/:propertyId" element={<AgentViewingForm/>}/>
                    <Route path="/showing-update/:showingId/:updateType" element={<ShowingUpdate/>}/>
                    <Route path="/tenant-showing-update/:showingId" element={<ClientReschedule/>}/>
                    <Route path="/tenant-confirm-slot/:showingId" element={<TenantConfirmSlot/>}/>
                    <Route path="/application-request/:propertyId" element={<AgentApplicationForm/>}/>
                    <Route path="/application-form/:applicationId/:pageType/:applicantId" element={<ApplicantFormPages/>}/>
                    <Route path="/agent-dashboard/:internalAgentId/:type?" element = {<AgentDashboard/>}/>
                    <Route path="/application-status/:applicationId" element={<AgentApplicationDashboard/>}/>
                </Routes>
            </PageLayout>
        )
    }
    else {
        return (
            <PageLayout>
                <div id ="modal-root"/>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                    stacked
                />
                <Routes>
                    <Route path='/' element={<Login/>}/>
                    <Route path='/properties/:propertyId?' element = {<DefaultLayout><Properties headerHeight = {headerHeight} /></DefaultLayout>}/>
                    <Route path='/leases/:leaseId?' element = {<DefaultLayout><Leases headerHeight = {headerHeight} /></DefaultLayout>}/>
                    <Route path='/showings/:showingId?' element = {<DefaultLayout><Showings headerHeight = {headerHeight}/></DefaultLayout>}/>
                    <Route path='/applications/:applicationId?' element = {<DefaultLayout><Applications headerHeight = {headerHeight}/></DefaultLayout>}/>
                    <Route path='/insights/:type?/:id?' element = {<DefaultLayout><Insights headerHeight = {headerHeight}/></DefaultLayout>}/>
                    <Route path='/messaging' element = {<DefaultLayout><Messaging headerHeight = {headerHeight}/></DefaultLayout>}/>
                    <Route path='/deals/:dealId?' element = {<DefaultLayout><Deals headerHeight = {headerHeight}/></DefaultLayout>}/>
                    <Route path='/documents/:dealId?' element = {<DefaultLayout><Documents headerHeight = {headerHeight}/></DefaultLayout>}/>
                    <Route path='/renewals/:renewalId?' element = {<DefaultLayout><Renewals headerHeight = {headerHeight}/></DefaultLayout>}/>
                    <Route path='/payments' element = {<DefaultLayout><Payments headerHeight = {headerHeight}/></DefaultLayout>}/>
                    <Route path='/settings' element = {<DefaultLayout><Settings/></DefaultLayout>}/>
                    <Route path='/create-client' element = {<CreateClient/>}/>
                </Routes>
            </PageLayout>
        )
     }
};

export default App;