import React, {useState,useEffect,useRef,useCallback,useContext,} from "react";
import DropdownSearch from "../dropdown-search/dropdown-search";

import {
  FilterBarContainer,
  FilterGroup,
  ButtonDiv,
  Title,
  SpaceDiv,
  FilterDiv,
  SortList,
  FilterLabel,
  SortContainer,
  SortItem,
  ClearButton,
} from "./deals-header.styles";

import { ReactComponent as Filter } from "../../assets/Filter.svg";
import { ReactComponent as FilterBlue } from "../../assets/Filter-blue.svg";

import { ReactComponent as Sort } from "../../assets/sort.svg";
import { ReactComponent as Down } from "../../assets/down-arrow.svg";
import { ApiContext } from "../../context/apiContext";

import { toast } from "react-toastify";


const DealsHeader = ({ filterFields, setFilterFields, setSortField }) => {
    const api = useContext(ApiContext);

    const [isFilter, setIsFilter] = useState(false);
    const [isSortOpen, setIsSortOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("Sort by");

    const [reset, setReset] = useState(false);

    const sortRef = useRef();

    const fetchOptionsData = useCallback(
        async (field, query) => {
            const params = new URLSearchParams();

            if (filterFields.paymentStatus !== "") {
                params.append("payment_status", filterFields.paymentStatus);
            }
            if (filterFields.leaseStatus !== "") {
                params.append("lease_status", filterFields.leaseStatus);
            }
            if (filterFields.property !== "") {
                params.append("listing_id", filterFields.property);
            }
            if (filterFields.agent !== "") {
                params.append("agent_id", filterFields.agent);
            }

            params.append("search_field", field === "property" ? "listing" : field);
            params.append("query", query);

            const apiURL = `/api/deal/search${
                params.toString() ? "?" + params.toString() : ""
            }`;
            try {
                const response = await api.get(apiURL);
                // console.log(response)
                return response;
            } catch (error) {
                toast.error(`Error loading data: ${error.message}`);
            }
        },[filterFields]
    );

    const handleSelect = (field, item) => {
        if (field === "paymentStatus") {
            setFilterFields((prev) => ({ ...prev, paymentStatus: item.value }));
        } else if (field === "leaseStatus") {
            setFilterFields((prev) => ({ ...prev, leaseStatus: item.value }));
        } else if (field === "property") {
            setFilterFields((prev) => ({ ...prev, property: item.id }));
        } else if (field === "agent") {
            setFilterFields((prev) => ({ ...prev, agent: item.id }));
        }
    };

    const handleOptionClick = (option) => {
        switch (option) {
            case "Lease Status":
                setSortField("leaseStatus");
                break;
            case "Payment Status":
                setSortField("paymentStatus");
                break;
            case "Agent":
                setSortField("agent_id");
                break;
            case "Property":
                setSortField("listing_id");
                break;
            default:
                setSortField("");
        }
        setSelectedOption(option);
        setIsSortOpen(false);
    };

    useEffect(() => {
        if (reset) {
            setFilterFields({
                paymentStatus: "",
                leaseStatus: "",
                property: "",
                agent: "",
            });
            setReset(false);
        }
    }, [reset]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (sortRef.current && !sortRef.current.contains(event.target)) {
                setIsSortOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [sortRef, setIsSortOpen]);

    return (
        <FilterBarContainer>
            <SpaceDiv>
                <Title>All Deals</Title>
                <FilterGroup>
                    <ButtonDiv
                        isFilter={ isFilter || filterFields.paymentStatus !== "" || filterFields.leaseStatus !== "" || filterFields.property !== "" || filterFields.agent !== "" }
                        onClick={() => setIsFilter(!isFilter)}
                    >
                        {
                          isFilter || filterFields.paymentStatus !== "" || filterFields.leaseStatus !== "" || filterFields.property !== "" || filterFields.agent !== "" ? 
                          <FilterBlue />:<Filter />
                        }
                        Filter By
                    </ButtonDiv>
                    <SortContainer>
                        <ButtonDiv onClick={() => setIsSortOpen(!isSortOpen)}>
                            <Sort />
                            {selectedOption} <Down />
                        </ButtonDiv>
                        {
                            isSortOpen && 
                            <SortList ref={sortRef}>
                                {selectedOption !== "Sort by" && (
                                    <SortItem onClick={() => handleOptionClick("Sort by")}>
                                        <strong>Clear</strong>
                                    </SortItem>
                                )}
                                {selectedOption !== "Payment Status" && (
                                    <SortItem onClick={() => handleOptionClick("Payment Status")}>
                                        Payment Status
                                    </SortItem>
                                )}
                                {selectedOption !== "Lease Status" && (
                                    <SortItem onClick={() => handleOptionClick("Lease Status")}>
                                        Lease Status
                                    </SortItem>
                                )}
                                {selectedOption !== "Property" && (
                                    <SortItem onClick={() => handleOptionClick("Property")}>
                                        Property
                                    </SortItem>
                                )}
                                {selectedOption !== "Agent" && (
                                    <SortItem onClick={() => handleOptionClick("Agent")}>
                                        Agent
                                    </SortItem>
                                )}
                            </SortList>
                        }
                    </SortContainer>
                </FilterGroup>
            </SpaceDiv>
          {
              isFilter && 
              <FilterGroup>
                  <FilterDiv>
                    <FilterLabel>Payment Status:</FilterLabel>
                    <DropdownSearch
                        key={1}
                        field="payment_status"
                        placeholder="Search Payment Status"
                        onSelect={(item) => handleSelect("paymentStatus", item)}
                        onClose={() => {
                            if (filterFields.paymentStatus !== "") {
                                setFilterFields((prev) => ({ ...prev, paymentStatus: "" }));
                            }
                        }}
                        noOptionsMessage="No payment status found"
                        fetchData={fetchOptionsData}
                        reset={reset}
                    />
                  </FilterDiv>
                  <FilterDiv>
                    <FilterLabel>Lease Status:</FilterLabel>
                    <DropdownSearch
                        key={1}
                        field="lease_status"
                        placeholder="Search Lease Status"
                        onSelect={(item) => handleSelect("leaseStatus", item)}
                        onClose={() => {
                            if (filterFields.leaseStatus !== "") {
                                setFilterFields((prev) => ({ ...prev, leaseStatus: "" }));
                            }
                        }}
                        noOptionsMessage="No Lease status found"
                        fetchData={fetchOptionsData}
                        reset={reset}
                    />
                  </FilterDiv>
                  <FilterDiv>
                    <FilterLabel>Property:</FilterLabel>
                    <DropdownSearch
                        key={3}
                        field="property"
                        placeholder="Search Property"
                        onSelect={(item) => handleSelect("property", item)}
                        onClose={() => {
                            if (filterFields.property !== "") {
                                setFilterFields((prev) => ({ ...prev, property: "" }));
                            }
                        }}
                        noOptionsMessage="No properties found"
                        fetchData={fetchOptionsData}
                        reset={reset}
                    />
                  </FilterDiv>
                  <FilterDiv>
                    <FilterLabel>Agent:</FilterLabel>
                    <DropdownSearch
                        key={4}
                        field="agent"
                        placeholder="Search agents"
                        onSelect={(item) => handleSelect("agent", item)}
                        onClose={() => {
                            if (filterFields.agent !== "") {
                                setFilterFields((prev) => ({ ...prev, agent: "" }));
                            }
                        }}
                        noOptionsMessage="No agents found"
                        fetchData={fetchOptionsData}
                        reset={reset}
                    />
                  </FilterDiv>
                  <ClearButton onClick={() => setReset(true)}>Clear Filters</ClearButton>
              </FilterGroup>
          }
        </FilterBarContainer>
    );
};

export default DealsHeader;
