import React, { createContext, useRef, useCallback, useState } from 'react';
import io from 'socket.io-client';

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
    // Move socket and isSocketReady to refs instead of state
    const socketRef = useRef(null);
    const isSocketReadyRef = useRef(false);
    const subscribersRef = useRef([]);
    const notificationsRef = useRef([]);
    const signedRef = useRef([]);
    const countRef = useRef([]);
    const [socketReady, setSocketReady] = useState(false);

    // Initialize socket connection only once using ref
    if (!socketRef.current) {
        // console.log('Initializing socket connection...');
        const newSocket = io('/chat', { autoConnect: false });
        
        newSocket.on('connect', () => {
            isSocketReadyRef.current = true;
            setSocketReady(true);
            console.log('Socket connected successfully.');
        });

        newSocket.on('disconnect', () => {
            isSocketReadyRef.current = false;
            setSocketReady(false);
            console.warn('Socket disconnected.');
        });


        newSocket.on('connect_error', (error) => {
            console.error('Socket connection error:', error);
        });

        // Setup message handlers
        const handleMessage = (message) => {
            // console.log('Received new chat message:', message);
            subscribersRef.current.forEach((callback) => callback(message));
        };

        const handleNotification = (notification) => {
            // console.log('Received new notification:', notification);
            notificationsRef.current.forEach((callback) => callback(notification));
        };

        const handleReadCount = (newCount) => {
            // console.log('Received new chat read count:', newCount);
            countRef.current.forEach((callback) => callback(newCount));
        };

        const handleFormSigned = (data) => {
            // console.log('Form signed event received:', data);
            signedRef.current.forEach((callback) => callback(data));
        };

        newSocket.on('new_chat_messages', handleMessage);
        newSocket.on('new_notifications', handleNotification);
        newSocket.on('chat_read_count', handleReadCount);
        newSocket.on('form_signed', handleFormSigned);

        newSocket.connect();
        socketRef.current = newSocket;
    }

    const authSocket = useCallback((agentId = null, accessToken = null) => {
        if (!socketReady) {
            // console.log('Socket is not ready. Cannot authenticate.');
            return;
        }

        if (accessToken) {
            console.log('Authenticating user with token...');
            socketRef.current.emit('authenticate', {
                token: accessToken,
                member_type: 'USER'
            });
        } else if (agentId) {
            // console.log('authing agent', agentId);
            socketRef.current.emit('authenticate', {
                member_id: agentId,
                member_type: 'AGENT'
            });
        } else {
            console.warn('No credentials provided for authentication.');
        }
    }, [socketReady]);

    const subscribeToNewNotifications = useCallback((callback) => {
        // console.log('Subscribing to new notifications...');
        notificationsRef.current.push(callback);
        return () => {
            // console.log('Unsubscribing from new notifications...');
            notificationsRef.current = notificationsRef.current.filter((cb) => cb !== callback);
        };
    }, []);

    const subscribeToNewMessages = useCallback((callback) => {
        // console.log('Subscribing to new messages...');
        subscribersRef.current.push(callback);
        return () => {
            // console.log('Unsubscribing from new messages...');
            subscribersRef.current = subscribersRef.current.filter((cb) => cb !== callback);
        };
    }, []);

    const subscribeToReadMessage = useCallback((callback) => {
        // console.log('Subscribing to read messages...');
        countRef.current.push(callback);
        return () => {
            // console.log('Unsubscribing from read messages...');
            countRef.current = countRef.current.filter((cb) => cb !== callback);
        };
    }, []);

    const subscribeToSignForm = useCallback((callback) => {
        // console.log('Subscribing to form signed events...');
        signedRef.current.push(callback);
        return () => {
            // console.log('Unsubscribing from form signed events...');
            signedRef.current = signedRef.current.filter((cb) => cb !== callback);
        };
    }, []);

    // Memoize the context value
    const value = React.useMemo(() => ({
        socket: socketRef.current,
        isSocketReady: isSocketReadyRef.current,
        authSocket,
        subscribeToNewMessages,
        subscribeToNewNotifications,
        subscribeToReadMessage,
        subscribeToSignForm
    }), [
        authSocket,
        subscribeToNewMessages,
        subscribeToNewNotifications,
        subscribeToReadMessage,
        subscribeToSignForm,
        socketReady
    ]);

    return (
        <SocketContext.Provider value={value}>
            {children}
        </SocketContext.Provider>
    );
};