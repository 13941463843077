import styled from "styled-components";

export const SettingsHeader = styled.div`
    display: flex;
    flex-direction: column;
    // align-items: center;
    padding: 20px;
    gap: 10px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    z-index: 1;
`;

export const Title = styled.label`
    color: var(--Black, #1C1C1C);

    /* Title/Large */
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;


export const ContentSection = styled.div`
padding: 20px 20px 25px;
    display: flex;
    flex-flow: column wrap;
    gap: 20px;
    overflow: hidden auto;
    justify-content: center;
    align-items: center;
`;

export const PasswordContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const PasswordDiv = styled.form`
    border: 1px solid #ddd;
    border-radius: 7px;
    padding: 15px;
    width: fit-content;
    min-width: 420px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const InputHeader = styled.div`
    color: #2C2C2C;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    width: 450px;
`;

export const SubmitButton = styled.button`
    border: none;
    border-radius: 10px;
    padding: 12px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
    // background-color: #064274;
    color: white;
    background-color: #E45A5C;
    &:hover{ 
        background-color: #B5373A; 
        transition: background-color 0.2s;
    }
    // &:hover{ 
    //     background-color: #002456;
    //     transition: background-color 0.2s;
    // }
`;