import styled from "styled-components";

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1000;
`;

export const ModalWrapper = styled.div`
    background: white;
    border-radius: 10px;
    display: ${props => props.animationState === "closed" ? 'none':'unset'};
    width: 60%;
    height: 70%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    // overflow: hidden;
    z-index: 1001;
    padding: 30px;
    gap: 20px;
    position: relative;
`;

export const FlexDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const SpaceDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: fit-content;
  overflow: auto;
`;

export const WrapDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    // overflow: auto;
`;

export const Section = styled.section`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export const SectionTitle = styled.div` // Changed to h2 for semantic HTML, adjust as necessary
    color: #2C2C2C;
    font-size: 16px;
    font-weight: 600;
`;

export const Title = styled.div`
    overflow: hidden;
    color: var(--Greyish-Black, #323232);
    leading-trim: both;

    text-edge: cap;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;


export const CloseButton = styled.button`
    cursor: pointer;
    border: none;
    background: #f7f7f7;
    width: 35px;
    padding: 8px;
    height: 35px;
    border-radius: 35px;
    &:hover {
        background-color: #f5f5f5; // A subtle hover effect
    }
`;

export const TextInput = styled.textarea`
    display: flex;
    padding: var(--12, 12px);
    font-family: Avenir;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 7px;
    border: 1px solid var(--F1, #F1F1F1);
`;


export const Heading = styled.div`
    color: #2c2c2c;
    font-size: 16px;
    font-weight: 500;
`;

export const SubmitButton = styled.button`
    background-color: #064274    ;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 12px;
    width: 100%;
    cursor: pointer;
    font-size: 14px;

    &:hover{ 
        background-color: #002456;
        transition: background-color 0.2s;
    }

`;

export const PreviousButton = styled(SubmitButton)`
    background-color: #F7F7F7;
    color: #323232;
    font-weight: 400;
    // cursor: pointer;
    
    &:hover{ 
        background-color: #dedede80;
        transition: background-color 0.2s;
    }
`;



export const UploadContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const AttachmentBox = styled.div`
    display: flex;
    padding: 10px;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    cursor: pointer;
    align-self: stretch;
    border: 1px solid var(--F1, #F1F1F1);
    border-radius: 7px;

    img {
        object-fit: cover;
        border-radius: 7px;
        max-height: 200px;
        width: 100%;
    }
`;

export const FileUploadLabel = styled.label`
    border: 1px dashed var(--Blue-100, #064274);
    background: #F0F8FF;
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    transition: border-color 0.3s;
    border-radius: 7px;
    span {
        color: red;
        font-weight: 500;
    }
`;

export const HiddenFileInput = styled.input`
    display: none;
`;

export const RoundDiv = styled.div`
    border-radius: 50px;
    background: var(--White, #FFF);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
`;

export const UploadInstruction = styled.p`
    text-align: center;
    color: #747474;
    font-size: 14px;
    margin-top: 10px;
`;


export const RemoveButton = styled.button`
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background: rgba(0, 0, 0, 0.8);
    }
`;
