import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { useNavigate } from 'react-router';
import Flatpickr from "react-flatpickr";

import { 
    Title, 
    Tooltip, 
    CategoryFlexDiv, 
    NavigationButton, 
    FilterItem, 
    FilterList ,
    Buttondiv,
    TitleDiv,
    VerticalChartContainer,
    ControlsDiv,
    SeeMoreDiv
} from '../shared.styles';
import { ReactComponent as Sort } from '../../../assets/sort.svg';
import { ReactComponent as Filter } from '../../../assets/Filter.svg';

const VerticalBarChart = ({ width, height, data, title, seeMore, showAll, fromDate, toDate, setFromDate, setToDate }) => {
    const ref = useRef();
    const yAxisRef = useRef();
    const filterRef = useRef();

    const navigate = useNavigate();
    
    const [tooltipContent, setTooltipContent] = useState('');
    const [tooltipVisibility, setTooltipVisibility] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

    const [sortOrder, setSortOrder] = useState('descending');
    const [showFilter, setShowFilter] = useState(false);
    const [countField, setCountField] = useState('Showings');

    const margins = { top: 30, right: 10, bottom: 10, left: 40 };
    
    const svgHeight = height - margins.top - margins.bottom;
    let svgWidth = width - margins.left - margins.right;
    const minBarWidth = 35;


    useEffect(() => {
        drawChart(sortOrder, countField);
    }, [data, sortOrder, countField]);

    const drawChart = (sortOrder, countField) => {
        // Sorting the data
        const sortedData = [...data].sort((a, b) => {
            const fieldMap = {
                'Showings': 'showingsCount',
                'Feedback': 'feedbackCount',
                'Avg. Rating': 'avgRating',
                // 'Applications': 'appsCount'
            };
            return sortOrder === 'ascending' ? a[fieldMap[countField]] - b[fieldMap[countField]] : b[fieldMap[countField]] - a[fieldMap[countField]];
        });

        const filteredData = showAll ? sortedData : sortedData.slice(0, 5);

        const fieldFocus = {
            'Showings': 'showingsCount',
            'Feedback': 'feedbackCount',
            'Avg. Rating': 'avgRating',
            // 'Applications': 'appsCount'
        }[countField] || 'feedbackCount';

        d3.select(ref.current).selectAll("*").remove();

        // Adjust SVG and bar widths
        if(showAll){
            let numBars = filteredData.length;
            let barWidth = Math.max(svgWidth / numBars,35); // Initial calculation of bar width
            svgWidth = Math.max(svgWidth,numBars * barWidth);
        }
       
        const svg = d3.select(ref.current)
            .append('svg')
            .attr('width', svgWidth + margins.left + margins.right - 40)
            .attr('height', height)
            .append('g')
            .attr('transform', `translate(0,${margins.top})`);

        const xScale = d3.scaleBand()
            .domain(filteredData.map(d => d.agentName))
            .range([0, svgWidth])
            .padding(0.5);

        const yScale = d3.scaleLinear()
            .domain([0, d3.max(filteredData, d => d[fieldFocus])])
            .range([svgHeight, 0]);


        // Draw bars with rounded tops
        svg.selectAll('.bar')
            .data(filteredData)
            .enter()
            .append('path')
            .attr('class', 'bar')
            .attr('d', d => {
                // Calculate the top left, top right, and bottom right corners of the bar
                const x1 = xScale(d.agentName);
                const x2 = xScale(d.agentName) + xScale.bandwidth();
                const y1 = yScale(d[fieldFocus]);
                const y2 = svgHeight;

                // Path starts at bottom left corner
                const path = `M ${x1} ${y2} ` + 
                            `L ${x1} ${y1 + 5} ` + // Line to start of top left corner curve
                            `Q ${x1} ${y1} ${x1 + 5} ${y1} ` + // Quarter-circle curve for top left corner
                            `L ${x2 - 5} ${y1} ` + // Line to start of top right corner curve
                            `Q ${x2} ${y1} ${x2} ${y1 + 5} ` + // Quarter-circle curve for top right corner
                            `L ${x2} ${y2} ` + // Line to bottom right corner
                            `Z`; // Close path back to start
                return path;
            })
            .attr('fill', '#3498DB')
            .on('mousemove', (event, d) => {
                setTooltipContent(`Property: <strong>${d.agentName}</strong>
                                  <br>Showings: <strong>${d.showingsCount}</strong>
                                  <br>Feedback: <strong>${d.feedbackCount}</strong>
                                  <br>Average Rating: <strong>${d.avgRating}</strong>`);
                                //   <br>Applications: <strong>${d.appsCount}</strong>`);
                setTooltipVisibility(true);
                setTooltipPosition({ x: event.pageX, y: event.pageY });
            })
            .on('mouseout', () => {
                setTooltipVisibility(false);
            })
            .on('click',(event, d) => navigate(`/insights/agent/${d.id}`))
            .style('cursor','pointer');
            
        // Add the text labels at the base of the bars or above based on bar height
        svg.selectAll('.agent-name')
            .data(filteredData)
            .enter()
            .append('text')
            .attr('class', 'agent-name')
            .attr('text-anchor', 'start') // Center the text on the bar
            .text(d => d.agentName)
            .attr('x',d=> xScale(d.agentName)+ xScale.bandwidth() * 0.5)
            .attr('y', d => {
                const barHeight = svgHeight - yScale(d[fieldFocus]); // Compute the height of the bar
                // const textLength = calculateTextWidth(d.agentName); // Calculate text length (not width in this context)
                
                if (barHeight < 100) { // If the bar is too short, place the text above the bar
                return yScale(d[fieldFocus]) - 5; // Adjusted position to move text above the bar
                } else { // If there is enough space inside the bar
                return yScale(d[fieldFocus]) + barHeight - 5; // Position the text inside the bar, near the bottom
                }
            })
            .attr('transform', d => {
                const x = xScale(d.agentName) + xScale.bandwidth() / 2; // Center of the bar
                const barHeight = svgHeight - yScale(d[fieldFocus]);
                const y = barHeight < 100 ? yScale(d[fieldFocus]) - 5 : yScale(d[fieldFocus]) + barHeight - 5; // Adjusted for bar height

                // const textWidth = calculateTextWidth(d.agentName) -20;
                const bufferBarHeight= barHeight*0.1;
                const translateAmount = barHeight < 30 ? `${bufferBarHeight}`: `0`;
                
                // Rotate text 90 degrees counterclockwise around the (x, y) point
                return `rotate(-90 ${x} ${y}) translate(${translateAmount})`; 
            })          
            .attr('fill', d => {
                const barHeight = svgHeight - yScale(d[fieldFocus]);

                return barHeight < 100 ? '#a4a4a4' : 'white'; // COlor
            })
            .attr('alignment-baseline', 'middle')
            .style('font-size', '12px')
            .style('font-weight', '500')
            .style('font-family', 'Avenir')
            .on('mousemove', (event, d) => {
                setTooltipContent(`Agent: <strong>${d.agentName}</strong>
                                <br>Showings: <strong>${d.showingsCount}</strong>
                                <br>Feedback: <strong>${d.feedbackCount}</strong>
                                <br>Average Rating: <strong>${d.avgRating}</strong>`
                                // <br>Applications: <strong>${d.appsCount}</strong>`
                                );
                setTooltipVisibility(true);
                setTooltipPosition({ x: event.pageX, y: event.pageY });
            })
            .on('mouseout', () => {
                setTooltipVisibility(false);
            })
            .on('click',(event, d) => navigate(`/insights/agent/${d.id}`))
            .style('cursor','pointer');

        // // // Draw the Y-axis
        // svg.append('g')
        //     .call(d3.axisLeft(yScale).ticks(5))
        //     .selectAll('text')
        //     .attr('fill', '#A4A4A4') // Set the color of the axis labels
        //     .attr('font-size', '10px'); // Set the font size of the axis labels
            


        // Add horizontal grid lines
        svg.append('g')
            .attr('class', 'grid')
            .call(d3.axisLeft(yScale).ticks(5)
                .tickSize(-svgWidth)
                .tickFormat(''))
            .lower()
            .selectAll('line')
            .attr('stroke', 'rgba(0, 0, 0, 0.1)')

        svg.select('.grid .domain').remove();

        // Draw the y-axis in a separate SVG
        d3.select(yAxisRef.current).selectAll("*").remove();
        const yAxisSvg = d3.select(yAxisRef.current)
            .append('svg')
            .attr('width', margins.left)
            .attr('height', height)
            .append('g')
            .attr('transform', `translate(${margins.left}, ${margins.top})`); // Adjust the position as needed

        yAxisSvg.call(d3.axisLeft(yScale).ticks(5))
            .selectAll('text')
            .attr('fill', '#A4A4A4') // Set the color of the axis labels
            .attr('font-size', '10px'); // Set the font size of the axis labels;

        // Style the lines (ticks) and the domain (axis line) of the Y-axis
        yAxisSvg.selectAll('.tick line, .domain')
            .attr('stroke', '#f1f1f1')
            .attr('stroke-opacity', 1); // You may want to set this to 1 for full opacity
    };

    const flipSortOrder = () => {
        setSortOrder(sortOrder === 'ascending' ? 'descending' : 'ascending');
    };

    const sortIconStyle = {
        transform: sortOrder === 'descending' ? 'rotate(90deg) ScaleX(-1)' : 'rotate(-90deg)' ,
        cursor: 'pointer'
    };

    // Click handler to detect clicks outside the modal container
    useEffect(() => {
        function handleClickOutside(event) {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setShowFilter(!showFilter)
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [filterRef, showFilter]); // Add modalRef and onClose to the dependency array

    const handleDateChange = (dates) => {
        if (dates.length === 2) {
            const [start, end] = dates;
    
            const formatDateToLocal = (date) => {
                const tzOffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
                const localISOTime = (new Date(date - tzOffset)).toISOString().slice(0, 10);
                return localISOTime;
            };
            // Update state with the corrected dates
            setFromDate(formatDateToLocal(start));
            setToDate(formatDateToLocal(end));
        }else{
            setFromDate('');
            setToDate('');
        }
    };

    return (
        <div>
            <CategoryFlexDiv>
                <TitleDiv>
                    { showAll && <NavigationButton onClick={seeMore}>{"<"}</NavigationButton>}
                    <Title>{title} - {countField}</Title>
                </TitleDiv>
                <ControlsDiv>
                    {
                        showAll &&
                        <Buttondiv>
                            <Flatpickr
                                data-enable-time={false}
                                value={[fromDate, toDate]}
                                onChange={handleDateChange}
                                options={{ mode: "range", dateFormat: "Y-m-d" }}
                                placeholder='Select Dates'
                            />
                        </Buttondiv>
                    }
                    <Buttondiv onClick={() => setShowFilter(!showFilter)}><Filter/>{'\u25BC'}</Buttondiv>
                    {
                        showFilter && 
                        <FilterList ref = {filterRef}>
                            {
                                ['Showings','Feedback','Avg. Rating'].map(filterVal=>{
                                    return (
                                        <FilterItem onClick={() => {setCountField(filterVal);setShowFilter(!showFilter);}}>
                                            <strong>{filterVal} {countField===filterVal && '✔'}</strong>    
                                        </FilterItem>
                                    )
                                })
                            }
                        </FilterList>
                    }
                    <Buttondiv onClick={flipSortOrder}><Sort style={sortIconStyle}/></Buttondiv>
                </ControlsDiv>
            </CategoryFlexDiv>
            <div style={{ display: 'flex' , width: width}}>
                <div ref={yAxisRef} style={{ flexShrink: 0, width: '40px' }}></div>
                <VerticalChartContainer  width={width - 40}>
                    <div ref={ref} />
                </VerticalChartContainer>
            </div>
            {tooltipVisibility && (
                <Tooltip style={{
                    opacity: 1,
                    left: `${tooltipPosition.x}px`,
                    top: `${tooltipPosition.y}px`,
                    position: 'absolute'
                }} dangerouslySetInnerHTML={{ __html: tooltipContent }} />
            )}
            {
                !showAll &&
                <SeeMoreDiv>
                    <Buttondiv onClick={seeMore}>See More</Buttondiv>
                </SeeMoreDiv>
            }
        </div>
    );
};

export default VerticalBarChart;
