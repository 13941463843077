import React, { useRef, useState, useEffect } from "react";
import ApplicantCard from "../applicant-card/applicant-card";
import { formatCurrency } from "../../utilities/utility";
import moment from 'moment'

import { 
    Label,
    Heading,
    WrapDiv,
    Currency,
    FlexDiv,
    Button,
    DropdownContent,
    DropdownItem
} from "./application-summary.styles";

const ApplicationSummary = ({property, applicationData, offerMoveinDeposits, setOtherApplicationState = null, openModal = null}) => {
    const [isDropdown, setIsDropdown] = useState(false);
    const dropdownRef = useRef();

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdown(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [dropdownRef]);
    return (
        <>
            <div>
                <Label>Property</Label>
                <FlexDiv>
                    <Heading>{property.address}</Heading>
                    <FlexDiv>
                    {
                        window.location.pathname.includes('application-status') && 
                        <Button onClick={() => setIsDropdown(!isDropdown)}>Apply to other apartments</Button>
                    }
                    {
                        
                        isDropdown &&
                        <DropdownContent ref = {dropdownRef}>
                            <DropdownItem onClick={() => setOtherApplicationState('Switch')}>Move Application to Different Property</DropdownItem>
                            <DropdownItem onClick={() => setOtherApplicationState('New')}>Add Application for New Property</DropdownItem>
                        </DropdownContent>
                        
                    }
                    </FlexDiv>
                </FlexDiv>
            </div>
            <div>
                <Label>Lease Tenure</Label>
                <Heading>
                    {moment(applicationData.requestedMoveIn).format('MMM DD, YYYY')} - {moment(applicationData.requestedMoveOut).format('MMM DD, YYYY')} ({applicationData.leaseDuration})
                </Heading>
            </div>
            <div style={{display:'flex', gap: '3px', flexDirection:'column'}}>
                <Label>Proposed Move-in Payments</Label>                    
                <WrapDiv>
                    {
                        Object.entries(offerMoveinDeposits || {}).map(([key, value]) => (
                            <div style = {{ minWidth:'100px'}}>
                                <Currency>{key}</Currency>
                                <Heading>{formatCurrency(value)}</Heading>
                            </div>
                        ))
                    }
                    {
                        window.location.pathname.includes('application-status')  && applicationData.status !== "Approved" && applicationData.status !== "Rejected" &&
                        <Button style ={{flex:1}} onClick={openModal}>Change Offer</Button>
                    }
                </WrapDiv>
            </div>
            <div style={{display:'flex', gap: '5px', flexDirection:'column'}}>
                <Label>Applicants</Label>
                <WrapDiv>
                    {
                        applicationData.applicants.map((applicant, index) => 
                            <ApplicantCard 
                                key ={index} 
                                index={index} 
                                applicant = {applicant} 
                            />
                        )
                    }
                </WrapDiv>
            </div>
        </>
    )
}

export default ApplicationSummary