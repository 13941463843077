import styled from "styled-components";

export const PaymentContainer = styled.div`
    padding: 10px 20px 10px 20px;
    border-radius: 7px;
    border: 1px solid var(--F1, #F1F1F1);
    gap: 5px;
    display: flex;
    width: 60%;
    min-width: 400px;
    flex-direction: column;
    // background: #F9F9F9;

`;

export const PaymentTitle = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    align-items: center;
    // margin-bottom: 10px;

    div {
        color: var(--Light-Grey, #A4A4A4);
        leading-trim: both;
        display: flex;

        text-edge: cap;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        &:nth-child(1){
            width: 200px;
        }

        &:nth-child(2){
            width: 118px;
            justify-content: start;
        }

        &:nth-child(3){
            width: 100px;
            // margin-left: auto;
            justify-content: end;
        }
    }

`;

export const Payment = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    align-items: center;
    
    border-bottom: 1px solid #EEE;

    div{
        color: var(--Greyish-Black, #323232);
        /* Body/Large */
        font-family: Avenir;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        align-items: center;
        gap: 10px;

        span { 
            background-color: #E3FFE6;
            color: #1CCB00;
            border-radius: 15px; // Fully rounded corners
            padding: 2px 10px; // Vertical and horizontal padding
            font-size: 11px; // Font size
            font-weight: 600; // Medium font weight
            cursor: pointer; // Cursor changes to pointer to indicate it's clickable
            user-select: none; // Prevent text selection
            display: inline-flex; // Use inline-flex to center content
            align-items: center; // Vertical align to center
            justify-content: center; // Horizontal align to center
            margin: 5px;
        }

        &:nth-child(1){
            width: 200px;
        }

        &:nth-child(3){
            width: 100px;
            // margin-left: auto;
            justify-content: end;
        }
    }

    input[type="date"] {
        color: #2C2C2C;
        font-size: 12px;
        padding: 5px;
        font-style: normal;
        font-weight: 500;
        display: flex;
        // flex-direction: column;
        border: 1px solid var(--F1, #F1F1F1);
        border-radius: 7px;
    }

    input[type="number"] {
        color: #2C2C2C;
        width: 80px;
        font-size: 12px;
        padding: 5px;
        font-style: normal;
        font-weight: 500;
        font-family: 'Avenir';
        display: flex;
        // flex-direction: column;
        border: 1px solid var(--F1, #F1F1F1);
        border-radius: 7px;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="%23000" font-family="Avenir" viewBox="0 0 12 12"><text x="0" y="9" font-size="11">$</text></svg>');
        background-repeat: no-repeat;
        padding-left: 20px;
        background-position: 5px center;
    }
`;

export const BrokerInput = styled.input`
    color: #2C2C2C;
    width: 150px;
    font-size: 12px;
    padding: 5px;
    font-style: normal;
    font-weight: 500;
    font-family: 'Avenir';
    display: flex;
    // flex-direction: column;
    border: 1px solid var(--F1, #F1F1F1);
    border-radius: 7px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="%23000" font-family="Avenir" viewBox="0 0 12 12"><text x="0" y="9" font-size="11">$</text></svg>');
    background-repeat: no-repeat;
    padding-left: 20px;
    background-position: 5px center;
    // height: 30px;
`;

export const AddButton = styled.div`
    cursor: pointer;
    border-radius: 56px;
    background: var(--Light-Blue, #F0F8FF);
    display: flex;
    padding: 4px 18px;
    justify-content: center;
    align-items: center;
    gap: 3px;
    color: var(--Blue-100, #064274);
    text-align: right;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    /* line-height: 20px; */
`;

export const DateWrapper = styled.div`
    display: flex;
    gap: 60px;

    label {
        display: flex;
        gap: 10px;
        // flex-direction: column;
        align-items: center;
    }

    div {
        color: var(--Light-Grey, #A4A4A4);
        leading-trim: both;
        display: flex;

        text-edge: cap;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

    }

    input[type="date"] {
        color: #2C2C2C;
        font-size: 12px;
        padding: 5px;
        font-style: normal;
        font-weight: 500;
        display: flex;
        // flex-direction: column;
        border: 1px solid var(--F1, #F1F1F1);
        border-radius: 7px;
    }
`;

export const CheckBoxLabel = styled.div`
    color: #2C2C2C;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 400px;
    width: 60%;
    border: 1px solid var(--F1, #F1F1F1);
    border-radius: 7px;
    padding: 5px 30px 5px 10px;
`;