import React, { useContext, useEffect, useState, useRef } from "react";
import DealCard from "../../components/deal-card/deal-card";
import DealDetail from "../../components/deal-detail-content/deal-detail-content";
import DealsHeader from "../../components/deals-header/deals-header";
import DealDetailHeader from "../../components/deal-detail-header/deal-detail-header";
import CreateLeaseModality from "../../components/create-lease-modality/create-lease-modality";
import { useParams } from "react-router-dom";
import { ContentSection } from "./deals.pages.styles";
import { ApiContext } from "../../context/apiContext";
import PopupForm from "../../components/popup-form/popup-form";
import { toast } from "react-toastify";
import LoaderCard from "../../components/loading-cards/loading-card";

const PAGE_SIZE = 10;

const Deals = ({ headerHeight }) => {
  const api = useContext(ApiContext);
  // const location = useLocation();
  const { dealId } = useParams(); // Extract the dealId from the URL
  const [isLoading, setIsLoading] = useState(true);
  const [deals, setDeals] = useState([]);
  const [focusDeal, setFocusDeal] = useState();
  const [modalId, setModalId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [forceFetch, setForceFetch] = useState(false);
  const isFirstRender = useRef(true);
  const [sortField, setSortField] = useState("");

  const [filterFields, setFilterFields] = useState({
    leaseStatus: "",
    paymentStatus: "",
    property: "",
    agent: "",
  });

  const observerRef = useRef(null);

  const observerElement = (
    <div 
      ref={observerRef} 
      style={{ height: "20px" }}
    />
  );

  // Fetch all deals data
  const fetchDeals = async (pageNumber) => {
    try {
      const params = new URLSearchParams();
      params.append("page", pageNumber);
      params.append("size", PAGE_SIZE);

      if (filterFields.paymentStatus)
        params.append("payment_status", filterFields.paymentStatus);
      if (filterFields.leaseStatus)
        params.append("lease_status", filterFields.leaseStatus);
      if (filterFields.property)
        params.append("listing_id", filterFields.property);
      if (filterFields.agent) params.append("agent_id", filterFields.agent);
      if (sortField && sortField !== "") params.append("ordering", sortField);

      const apiURL = `/api/deal/all${
        params.toString() ? "?" + params.toString() : ""
      }`;

      const response = await api.get(apiURL);

      if (pageNumber === 1) {
        setDeals(response.results);
      } else {
        setDeals((prev) => [...prev, ...response.results]);
      }
      setHasMore(!!response.next);
    } catch (error) {
      setHasMore(false);
      toast.error(`There was an error loading the data: ${error.message}`);
    } finally {
      if (forceFetch) setForceFetch(false);
      setIsLoading(false);
    }
  };

  // Fetch single deal data
  const fetchSingleDeal = async (id) => {
    try {
      const data = await api.get(`/api/deal/detail`, { dealId: id });
      setFocusDeal(data);
    } catch (error) {
      toast.error(`There was an error loading the deal: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Initial data fetch
  useEffect(() => {
    if (dealId) {
      // If a specific dealId is in the URL, fetch only that deal
      setIsLoading(true);
      fetchSingleDeal(dealId);
    } else {
      // Otherwise, fetch the list of deals
      if (isFirstRender.current) {
        fetchDeals(1);
        isFirstRender.current = false;
      } else {
        setPage(1);
        setForceFetch(true);
      }
    }
  }, [dealId]);

  // Fetch data when `page` changes
  useEffect(() => {
    if (!dealId && (page > 1 || forceFetch)) fetchDeals(page);
  }, [page, forceFetch]);

  // Infinite scrolling
  useEffect(() => {
    const loadMoreEntries = (entries) => {
      const target = entries[0];
      if (target.isIntersecting && hasMore) {
        setPage((prev) => prev + 1);
      }
    };

    const observer = new IntersectionObserver(loadMoreEntries, {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    });

    if (observerRef.current) observer.observe(observerRef.current);

    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current);
    };
  }, [hasMore]);

  // Handle deal click to focus on details
  const handleClick = (id) => {
    if (id) {
      // Navigating to a specific deal
      window.history.pushState({}, "", `/deals/${id}`);
      fetchSingleDeal(id);
    } else {
      // Navigating back to the list
      window.history.pushState({}, "", `/deals`);
      setFocusDeal(null);
  
      // Fetch deals only if they are not already loaded
      if (deals.length === 0) {
        fetchDeals(1); // Fetch all deals if the list is empty
      }
    }
  };

  const toggleModal = async (id) => {
    setModalId(id);
    setIsModalOpen((prev) => !prev);
  };

  const updateDeal = (updateData, depositType = null) => {
    if (depositType) {
      setFocusDeal((prev) => ({
        ...prev,
        dealMoveinDeposits: {
          ...prev.dealMoveinDeposits,
          [depositType]: updateData[depositType],
        },
        amountDue: prev.amountDue - updateData[depositType].amount,
        amountPaid: prev.amountPaid + updateData[depositType].amount,
      }));
    } else {
      if (focusDeal) {
        setFocusDeal((prev) => ({
          ...prev,
          ...updateData,
        }));
      } else {
        setDeals((currentDeals) =>
          currentDeals.map((deal) =>
            deal.id === updateData.dealId ? { ...deal, ...updateData } : deal
          )
        );
      }
    }
  };

  return (
    <>
      {!focusDeal ? (
        <>
          <DealsHeader
            filterFields={filterFields}
            setFilterFields={setFilterFields}
            setSortField={setSortField}
          />
          {isLoading ? (
            <ContentSection>
              <LoaderCard size="large" />
            </ContentSection>
          ) : (
            <ContentSection headerHeight={headerHeight}>
              {deals.map((deal) => (
                <DealCard
                  key={deal.id}
                  activateModal={() => toggleModal(deal.id)}
                  deal={deal}
                  handleClick={() => handleClick(deal.id)}
                />
              ))}
              {observerElement}
            </ContentSection>
          )}
        </>
      ) : (
        <>
          <DealDetailHeader
            deal={focusDeal}
            filteredDealIds={deals.map((deal) => deal.id)}
            setDeals={setDeals}
            handleClick={handleClick}
          />
          {isLoading ? (
            <ContentSection>
              <LoaderCard size="large" />
            </ContentSection>
          ) : (
            <ContentSection headerHeight={headerHeight}>
              <DealDetail
                deal={focusDeal}
                activateModal={() => toggleModal(focusDeal.id)}
                updateDeal={updateDeal}
              />
            </ContentSection>
          )}
        </>
      )}
      {isModalOpen &&
        (() => {
          const deal = deals.find((deal) => deal.id === modalId);
          if (!deal) return null;
          if (deal.leaseDoc) {
            return (
              <PopupForm
                showPopupUrl={deal.leaseDoc}
                handleClose={() => {
                  setModalId(null);
                  toggleModal();
                }}
              />
            );
          } else {
            return (
              <CreateLeaseModality
                onClose={toggleModal}
                modalId={modalId}
                updateDeal={updateDeal}
              />
            );
          }
        })()}
    </>
  );
};

export default Deals;
