import React, { useState, useEffect, useRef } from "react";
import { formatDate, generateNextDates, generateTimeSlots, checkDateRequirement } from "../../utilities/utility";
import ViewingRequestAcknowledgment from "../viewing-confirmation-text/viewing-confirmation-text";
import FormInput from "../form-input/form-input";
import {
    Canvas, AppointmentContainer, DateList, TimeList, DateButton, TimeButton,
    Section, Title, SectionTitle, Heading, NextButton, Dropdown, DropdownDiv,
    FlexDiv, Label, Radio, ButtonDiv, PreviousButton, SubmitButton
} from './viewing-form.styles';
import BrokerageSearch from "../brokerage-search/brokerage-search";

const ViewingForm = ({ property }) => {
    const [isFocus, setIsFocus] = useState('Appointment');
    const scroll = useRef();
    const [formData, setFormData] = useState({
        guarantor: 'no',
        agentName: '',
        agentEmail: '',
        agentPhone: '',
        clientType: 'Students',
        agentId: '',
        brokerageName: '',
        selections: {}
    });
    const [errors, setErrors] = useState({});
    const [activeDate, setActiveDate] = useState('');

    // Load form data from local storage when the component mounts
    useEffect(() => {
        const savedFormData = localStorage.getItem('viewingFormData');
        if (savedFormData) {
            const parsedData = JSON.parse(savedFormData);
            // console.log("Loaded from localStorage:", parsedData);
            const { selections, ...rest } = parsedData;
            setFormData(prevState => ({ ...prevState, ...rest }));
        }
    }, []);

    const dates = generateNextDates(property?.isVacant, true);//new URL(window.origin).hostname.split('.')[0] === "starrealty"); // Meed to fix this to be more scalable
    const times = generateTimeSlots(property?.isVacant, activeDate, true);//new URL(window.origin).hostname.split('.')[0] === "starrealty");  // Meed to fix this to be more scalable
    const dateList = dates.map(date => formatDate(date)['formattedDate']);

    const handleChange = (name, value) => {
        setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    };

    const toggleDate = (date) => {
        setActiveDate(date);
    };

    const toggleTime = (time) => {
        if (activeDate) {
            const currentTimes = formData.selections[activeDate] || [];
            let newSelections = { ...formData.selections };

            if (currentTimes.includes(time)) {
                const newTimes = currentTimes.filter(t => t !== time);
                newSelections[activeDate] = newTimes;
                if (newTimes.length === 0) {
                    delete newSelections[activeDate];
                }
            } else {
                newSelections[activeDate] = [...currentTimes, time];
            }
            handleChange('selections', newSelections);
        }
    };

    const handleFocus = (element) => {
        if (checkDateRequirement(formData.selections, property?.isVacant)) {
            setIsFocus(element);
            if (scroll.current) {
                scroll.current.scrollTo({ top: 0, behavior: 'smooth' });
            }
        } else {
            if (property?.isVacant) {
                alert('Select at least 1 date and at least 1 time slot for each date');
            } else if(new URL(window.origin).hostname.split('.')[0] === "starrealty"){ // Need to change this to a better logic
                alert('Select at least 2 time slots for each date');
            }else {
                alert('Select at least 2 dates and 2 time slots for each date');
            }
        }
    };

    const validateInputs = () => {
        let newErrors = {};
        if (!formData.agentName) newErrors.agentName = 'Name is required';
        if (!formData.brokerageName) newErrors.brokerageName = 'Brokerage is required';

        const agentIdRegex = /^[0-9]*$/
        if (!formData.agentId) {
            newErrors.agentId = 'License is required';
        } else if (!agentIdRegex.test(formData.agentId)){
            newErrors.agentId = 'Real Estate License is incorrect';
        }
         // Email validation
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!formData.agentEmail) {
            newErrors.agentEmail = 'Email is required';
        } else if (!emailRegex.test(formData.agentEmail)) {
            newErrors.agentEmail = 'Enter a valid email';
        }

        // Phone validation
        const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (!formData.agentPhone) {
            newErrors.agentPhone = 'Phone is required';
        } else if (!phoneRegex.test(formData.agentPhone)) {
            newErrors.agentPhone = 'Enter a valid phone number';
        }
        // if (!formData.guarantor) newErrors.guarantor = 'Select guarantor type';
        // if (!formData.clientType) newErrors.clientType = 'Select client type';
        // Add other validation rules here
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = () => {
        if (validateInputs()) {
            const payload = {
                ...formData,
                propertyId: property.id
            };
            // Extract the subdomain from the current URL
            const { hostname } = window.location;
            const subdomain = hostname.split('.')[0];
            // console.log(payload)
            // Log the payload or send it to the API endpoint
            // console.log("Submitting the following data to /api/showings/: ", payload);

            // Check if subdomain is valid
            if (!subdomain) {
                alert('Invalid subdomain. Please ensure you are using the correct URL.');
                return;
            }

            // Example of sending data to your API endpoint
            fetch(`/api/showing/new?company=${subdomain}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const { selections, ...dataToSave } = formData;
                // console.log("Saving to localStorage:", dataToSave);
                localStorage.setItem('viewingFormData', JSON.stringify(dataToSave));
                return response.json();
            })
            .then(data => {
                setIsFocus('Complete');
            })
            .catch((error) => {
                alert('There was an issue submitting your request. Please email us at support@avaasa.us for assistance.');
            });
        }
    };

    return (
        <Canvas ref = {scroll}>
            {isFocus !== 'Complete' ? (
                <>
                    <Title>Viewing Form</Title>
                    <Heading style={{margin: ' -15px 0px'}}>We ensure that our tenants receive a minimum of 24 hours notice for any showing requests.</Heading>
                    <AppointmentContainer>
                        {isFocus === 'Appointment' ? (
                            <>
                                <SectionTitle>Appointment Date and Time*</SectionTitle>
                                <Section>
                                    <Heading>Pick Date (select at least two date slots)</Heading>
                                    <DateList>
                                        {dateList.map((date) => (
                                            <DateButton
                                                key={date}
                                                selected={date === activeDate}
                                                highlighted={Object.keys(formData.selections).includes(date)}
                                                onClick={() => toggleDate(date)}
                                            >
                                                {date}
                                            </DateButton>
                                        ))}
                                    </DateList>
                                </Section>
                                <Section>
                                    <Heading>Pick Time (select at least two time slots for each date slot)</Heading>
                                    <TimeList>
                                        {times.map((time) => (
                                            <TimeButton
                                                key={time}
                                                selected={formData.selections[activeDate]?.includes(time)}
                                                onClick={() => toggleTime(time)}
                                            >
                                                {time}
                                            </TimeButton>
                                        ))}
                                    </TimeList>
                                </Section>
                                <NextButton onClick={() => handleFocus('Detail')}>Next</NextButton>
                            </>
                        ) : (
                            isFocus === 'Detail' && (
                                <>
                                    <Section>
                                        <SectionTitle>Agent Details</SectionTitle>
                                        <FormInput
                                            label={'Name'}
                                            isRequired={true}
                                            type={'text'}
                                            handleChange={(value) => handleChange('agentName', value)}
                                            error={errors.agentName}
                                            defaultValue={formData.agentName}
                                        />
                                        <FormInput
                                            label={'Email'}
                                            isRequired={true}
                                            type={'email'}
                                            handleChange={(value) => handleChange('agentEmail', value)}
                                            error={errors.agentEmail}
                                            defaultValue={formData.agentEmail}
                                        />
                                        <FormInput
                                            label={'Phone'}
                                            isRequired={true}
                                            type={'phone'}
                                            handleChange={(value) => handleChange('agentPhone', value)}
                                            error={errors.agentPhone}
                                            defaultValue={formData.agentPhone}
                                        />
                                        <FormInput
                                            label={'Real-estate License'}
                                            isRequired={true}
                                            type={'text'}
                                            handleChange={(value) => handleChange('agentId', value)}
                                            error={errors.agentId}
                                            defaultValue={formData.agentId}
                                        />
                                    </Section>
                                    <Section>
                                        <SectionTitle>Brokerage Details</SectionTitle>
                                        <BrokerageSearch
                                            handleChange={(value) => handleChange('brokerageName', value)}
                                            label={'Brokerage Name'}
                                            isRequired={true}
                                            error={errors.brokerageName}
                                            defaultValue={formData.brokerageName}
                                        />
                                    </Section>
                                    <Section>
                                        <SectionTitle>Client Details</SectionTitle>
                                        <DropdownDiv>
                                            <div>Client Type<span style={{ color: 'red' }}>*</span></div>
                                            <Dropdown onChange={(e) => handleChange('clientType', e.target.value)} value={formData.clientType}>
                                                <option value="Students">Students</option>
                                                <option value="Working Professionals">Working Professionals</option>
                                                <option value="Both">Both</option>
                                            </Dropdown>
                                        </DropdownDiv>
                                        <DropdownDiv>
                                            <div>Guarantor Status<span style={{ color: 'red' }}>*</span></div>
                                            <FlexDiv>
                                                <Label>
                                                    Yes
                                                    <Radio
                                                        type="radio"
                                                        value="yes"
                                                        checked={formData.guarantor === "yes"}
                                                        onChange={(event) => handleChange('guarantor', event.target.value)}
                                                    />
                                                </Label>
                                                <Label>
                                                    No
                                                    <Radio
                                                        type="radio"
                                                        value="no"
                                                        checked={formData.guarantor === "no"}
                                                        onChange={(event) => handleChange('guarantor', event.target.value)}
                                                    />
                                                </Label>
                                            </FlexDiv>
                                        </DropdownDiv>
                                    </Section>
                                    <ButtonDiv>
                                        <PreviousButton onClick={() => setIsFocus('Appointment')}>Previous</PreviousButton>
                                        <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
                                    </ButtonDiv>
                                </>
                            )
                        )}
                    </AppointmentContainer>
                </>
            ) : (
                <ViewingRequestAcknowledgment
                    agentEmail={formData?.agentEmail}
                    managementEmail={property?.managementEmail}
                />
            )}
        </Canvas>
    );
};

export default ViewingForm;
