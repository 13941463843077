import React, { useState, useMemo } from "react";
import PropertyInfo from "../property-info/property-info";
import RenewalPackage from './renewal-package/renewal-package'
import {
    Container,
    Canvas,
    TabContentHeader,
    TabHolder,
    Tab,
    CanvasSmall,
    FlexDiv,
    NavigationButton,
    SpaceDiv,
    TextNav,
    FilterBarContainer,
    ActionButton,
    RemindButton,
    RelistButton,
    ButtonDiv
} from "./renewals-detail.styles";
import TenantInfo from "./tenant-info/tenant-info";
import Forms from "./forms/forms";
import Offers from "./offers/offers";


const RenewalDetail = ({ 
    focusRenewal, nextRenewalId, previousRenewalId, totalRenewals, currentIndex, 
    handleClick, setFocusRenewal, initiateOffer, showRenewalModal, setIsLeaseModalOpen, handleRenewalAction
}) => {
    const [activeTab, setActiveTab] = useState("Tenants");

    const onTabClick = (tab) => {
        setActiveTab(tab);
    };


    const handleButtonClick = (e, callback) => {
        e.stopPropagation(); // Prevent the Card's onClick from triggering
        callback && callback();
    };



    const renderTabContent = useMemo(() => {
        switch(activeTab){
            case 'Tenants':
                return <TenantInfo
                    tenants = {focusRenewal.tenants}
                    applicants = {focusRenewal.applicants}
                    renewalId = {focusRenewal.renewalId}
                    applicationStatus = {focusRenewal.applicationStatus}
                    setActiveTab = {setActiveTab}
                />
            case "Forms":
                return <Forms
                    tenants = {focusRenewal.tenants}
                    applicants = {focusRenewal.applicants}
                    renewalId = {focusRenewal.renewalId}
                    lease = {focusRenewal.leases}
                    setIsLeaseModalOpen = {setIsLeaseModalOpen}
                />
            case "Offers":
                return <Offers
                    offers = {focusRenewal.offers}
                    focusRenewal = {focusRenewal}
                    initiateOffer={initiateOffer}
                />
            case "Application":
                return <RenewalPackage
                    focusRenewal = {focusRenewal}
                    setFocusRenewal = {setFocusRenewal}
                    showRenewalModal = {showRenewalModal}
                />
            case "Payments":
                return <></>
        }
    },[focusRenewal, activeTab])

    return (
        <Container>
            <FilterBarContainer>
                <SpaceDiv>
                    <FlexDiv>
                        <NavigationButton onClick={() => handleClick()}>{"<"}</NavigationButton>
                        <TextNav>Renewals/
                            <span>Detail View</span>
                        </TextNav>
                    </FlexDiv>
                    <FlexDiv>
                        {
                            previousRenewalId && 
                            <NavigationButton onClick={() => handleClick(previousRenewalId)}>{"<"}</NavigationButton>
                        }
                        <span>
                            Renewal {currentIndex + 1} of {totalRenewals}
                        </span>
                        {
                            nextRenewalId && 
                            <NavigationButton onClick={() => handleClick(nextRenewalId)}>{">"}</NavigationButton>
                        }
                    </FlexDiv>
                </SpaceDiv>
                <SpaceDiv>
                    <PropertyInfo
                        property={focusRenewal.property}
                        offerPrice={focusRenewal.latestOfferPrice}
                    />
                    <ButtonDiv>
                        {
                            focusRenewal.renewalStatus === "Pending" && 
                            <ActionButton onClick={(e) => handleButtonClick(e, () => initiateOffer())}>Send Request</ActionButton>
                        }
                        {
                            (focusRenewal.renewalStatus === "Replacement Requested" || focusRenewal.renewalStatus === "Awaiting Responses") && 
                            <RemindButton onClick = {(e) => handleButtonClick(e, () => handleRenewalAction('follow-up', focusRenewal.renewalId))}>Remind</RemindButton>
                        }
                        {
                            focusRenewal.renewalStatus === "Form Signing" && 
                            <RemindButton onClick = {(e) => handleButtonClick(e, () => handleRenewalAction('follow-up', focusRenewal.renewalId))}>Follow-Up</RemindButton>
                        }
                        {
                            focusRenewal.renewalStatus === "Payment Pending" && 
                            <RemindButton onClick = {(e) => handleButtonClick(e, () => handleRenewalAction('follow-up', focusRenewal.renewalId))}>Request Payment</RemindButton>
                        }
                        {
                            focusRenewal.renewalStatus === "Partially Accepted" && 
                            <ActionButton onClick = {(e) => handleButtonClick(e, () => handleRenewalAction('replacement', focusRenewal.renewalId))}>Request Replacement</ActionButton>
                        }
                        {
                            focusRenewal.renewalStatus === "Denied" && 
                            <RelistButton onClick = {(e) => handleButtonClick(e, () => handleRenewalAction('relist', focusRenewal.renewalId))}>Re-list</RelistButton>
                        }
                        {
                            focusRenewal.renewalStatus === "Accepted" && 
                            <ActionButton onClick={(e) => handleButtonClick(e, () => showRenewalModal(focusRenewal))}>
                                {focusRenewal.totalApplicants === 0 ? "Send Renewal Form":"Send Lease"}
                            </ActionButton>
                        }
                    </ButtonDiv>
                </SpaceDiv>
            </FilterBarContainer>
            <Canvas>
                <TabContentHeader>
                    <TabHolder>
                        <Tab
                            onClick={() => onTabClick("Tenants")}
                            active={activeTab === "Tenants"}
                        >
                            Tenants
                        </Tab>
                        {
                            focusRenewal.renewalStatus !== "Pending" &&
                            <Tab
                                onClick={() => onTabClick("Offers")}
                                active={activeTab === "Offers"}
                            >
                                Offers
                            </Tab>
                        }
                        
                        {
                            focusRenewal.totalApplicants > 0 &&
                            <Tab
                                onClick = {() => onTabClick("Application")}
                                active={activeTab === "Application"}
                            >
                                Application Package
                            </Tab>
                        }
                        {
                            focusRenewal.renewalStatus === "Form Signing" &&
                            <Tab
                                onClick={() => onTabClick("Forms")}
                                active={activeTab === "Forms"}
                            >
                                {
                                    focusRenewal.totalApplicants > 0 ? "Lease": "Renewal Forms"
                                }
                            </Tab>
                        }
                        {
                            (focusRenewal.renewalStatus === "Pending Payments" || focusRenewal.renewalStatus === "Form Signing" 
                            || focusRenewal.renewalStatus === "Renewed") &&
                            <Tab
                                onClick={() => onTabClick("Payments")}
                                active={activeTab === "Payments"}
                            >
                                Payments
                            </Tab>
                        }
                    </TabHolder>
                </TabContentHeader>
                {renderTabContent}
            </Canvas>

        </Container>
    )
}

export default RenewalDetail