import React, { useEffect, useState } from 'react';
import FormInput from '../../form-input/form-input';
import {
    Section, SectionTitle, WrapDiv, ApplicantContainer, FlexDiv, Dropdown, DropdownDiv, NextButton, PreviousButton, GapDiv,
    AttachmentBox, HiddenFileInput, UploadInstruction, PreviewContainer, PDFIconWrapper, FileName, FileUploadLabel, RoundDiv,
    Label, Heading
} from '../shared-styles';

import {
    TableContainer, TableRow, TableCell, DeleteButton, OpenButton
} from './add-applicant-package.styles'

import { ReactComponent as Document } from '../../../assets/document-upload.svg'


const AddApplicantPlusPackage = ({ applicationData, setApplicationData, setIsFocus }) => {
    const [errors, setErrors] = useState({});
    const [applicationPackage, setApplicationPackage] = useState(applicationData.applicationPackage || null);
    const [previewUrl, setPreviewUrl] = useState('');
    const [applicant, setApplicant] = useState({
        id: null,
        name: '',
        email: '',
        applicantType: null,
        requiredDocs: [],
        hasCosigner: false,
        cosigner: {}
    });
    const [applicants, setApplicants] = useState(applicationData.applicants || []);

    useEffect(() => {
        if(applicationData.applicationPackage){
            const objectUrl = URL.createObjectURL(applicationData.applicationPackage);
            setPreviewUrl(objectUrl);
        }
    },[])

    const addRemoveGuarantor = (value) => {
        if(value){
            setApplicant(prev => ({
                ...prev, 
                hasCosigner: true,
                cosigner: {
                    ...prev.cosigner,
                    requiredDocs: []
                }
            }))
        }else{
            setApplicant(prev => ({
                ...prev, 
                hasCosigner: false,
                cosigner: {
                    name: '',
                    email: '',
                    requiredDocs: []
                }
            }))
        }
    }

    // Function to handle adding an applicant
    const handleAddApplicant = () => {
        // Validate the current applicant
        const newErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex for validation

        if (!applicant.name) newErrors.name = "Name is required";
    
        if (!applicant.email) {
            newErrors.email = "Email is required";
        } else if (!emailRegex.test(applicant.email)) {
            newErrors.email = "Enter a valid email address";
        }
    
        if (!applicant.applicantType) newErrors.applicantType = "Type is required";
        
        if (applicant.hasCosigner === true) {
            if (!applicant.cosigner.name) newErrors.cosignerName = "Name is required";
            
            if (!applicant.cosigner.email) {
                newErrors.cosignerEmail = "Email is required";
            } else if (!emailRegex.test(applicant.cosigner.email)) {
                newErrors.cosignerEmail = "Enter a valid email address";
            }
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
    
        // If valid, add to applicants and reset the form
        setErrors({});
        setApplicants([...applicants, { ...applicant, id: Date.now() }]);
        setApplicant({ id: null, name: '', email: '', applicantType: null, requiredDocs: [], hasCosigner: false, cosigner: {} });
    };
    

    // Function to handle deleting an applicant
    const handleDelete = (id) => {
        setApplicants(applicants.filter(applicant => applicant.id !== id));
    };

    // Function to handle form input changes
    const handleInputChange = (field, value) => {
        setApplicant(prev => ({ ...prev, [field]: value }));
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'application/pdf') {
            const objectUrl = URL.createObjectURL(file);
            setPreviewUrl(objectUrl);
            setApplicationPackage(file);
        }
    };


    const onRemove = () => {
        setPreviewUrl('');
        setApplicationPackage();
    };

    const handleSubmit = () => {
        const newErrors = {};
        if(!applicationPackage) newErrors.attachment = "Upload the application package"
        if(applicants.length === 0) newErrors.applicants = "Add applicants for the property"

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        // If valid, add to applicants and reset the form
        setErrors({});
        setApplicationData(prev => ({
            ...prev,
            applicants: applicants,
            applicationPackage: applicationPackage
        }));

        setIsFocus('Review')
    }

    console.log(applicants)

    return (
        <>
            <ApplicantContainer style = {{gap: '30px'}}>
                <Section style={{ gap: '10px' }}>
                    <SectionTitle>Add an Applicant</SectionTitle>
                    <WrapDiv>
                        <FormInput
                            label="Name"
                            type="text"
                            isRequired={true}
                            defaultValue={applicant.name}
                            handleChange={(value) => handleInputChange('name', value)}
                            flexBasis={true}
                            error={errors.name}
                        />
                        <FormInput
                            label="Email"
                            type="email"
                            isRequired={true}
                            defaultValue={applicant.email}
                            handleChange={(value) => handleInputChange('email', value)}
                            flexBasis={true}
                            error={errors.email}
                        />
                        <DropdownDiv>
                            <FlexDiv>
                                <div>Applicant Type<span style={{ color: 'red' }}>*</span></div>
                                {errors.applicantType && <span style={{ color: 'red' }}>{errors.applicantType}</span>}
                            </FlexDiv>
                            <Dropdown
                                onChange={(e) => handleInputChange('applicantType', e.target.value)}
                                value={applicant.applicantType || ''}
                            >
                                <option value="" disabled style={{color:"#gray !important"}}>Select Applicant Type</option>
                                <option value="International Student">International Student</option>
                                <option value="Student">Student</option>
                                <option value="Working Professional">Working Professional</option>
                                <option value="Family">Family</option>
                            </Dropdown>
                        </DropdownDiv>
                        <Section style={{width: '-webkit-fill-available', marginBottom: '10px'}}>
                            <Heading>Add Cosigner?</Heading>
                            <GapDiv>
                                <Label style = {{color: '#2C2C2C', display:'flex', alignItems:'center', gap:'5px'}}>
                                    <input
                                        type="radio"
                                        value="Yes"
                                        name="Guarantor"
                                        checked={applicant.hasCosigner === true} 
                                        onChange={(e) => addRemoveGuarantor(true)}
                                    />
                                    Yes
                                </Label>
                                <Label style = {{color: '#2C2C2C', display:'flex', alignItems:'center', gap:'5px'}}>
                                    <input
                                        type="radio"
                                        value="No"
                                        name="Guarantor"
                                        checked={applicant.hasCosigner === false} 
                                        onChange={(e) => addRemoveGuarantor(false)}
                                    />
                                    No
                                </Label>
                            </GapDiv>
                        {
                            applicant?.hasCosigner &&
                            <WrapDiv>
                                <FormInput
                                    label="Cosigner Name"
                                    type="text"
                                    isRequired={true}
                                    value={applicant?.cosigner?.name}
                                    handleChange={(value) => setApplicant(prev => ({...prev, cosigner: {...prev.cosigner, name: value}}))}
                                    flexBasis = {true}
                                    error={errors.cosignerName }
                                />
                                <FormInput
                                    label="Cosigner Email"
                                    type="text"
                                    isRequired={true}
                                    value={applicant?.cosigner?.email}
                                    handleChange={(value) => setApplicant(prev => ({...prev, cosigner: {...prev.cosigner, email: value}}))}
                                    flexBasis = {true}
                                    error = {errors.cosignerEmail }
                                />
                            </WrapDiv>
                        }
                        </Section>
                        <NextButton onClick={handleAddApplicant}>Add Applicant</NextButton>
                    </WrapDiv>
                </Section>
                    {/* Table to display added applicants */}
                <Section>
                    <SectionTitle>Applicant List</SectionTitle>
                    <TableContainer>
                        <TableRow header={true}>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                        {
                            applicants.map(applicant => (
                                <TableRow key={applicant.id}>
                                    <TableCell>{applicant.name}</TableCell>
                                    <TableCell>{applicant.email}</TableCell>
                                    <TableCell>{applicant.applicantType}</TableCell>
                                    <TableCell>
                                        {/* <EditButton>Edit</EditButton> */}
                                        <DeleteButton onClick={() => handleDelete(applicant.id)}>Delete</DeleteButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                        {
                            applicants.length === 0 &&
                            <TableRow style = {{justifyContent:'center', fontWeight:600}}>No Applicants Added</TableRow>
                        }
                    </TableContainer>
                </Section>
                <Section>
                    <SectionTitle>Upload Application Package</SectionTitle>
                    {
                        previewUrl ?
                        <AttachmentBox>
                            <PreviewContainer>
                                <PDFIconWrapper>
                                    <Document /> {/* Use a PDF icon or SVG component */}
                                </PDFIconWrapper>
                                <FileName>{applicationPackage.name}</FileName>
                                <OpenButton onClick={() => window.open(previewUrl, '_blank')}>
                                    Open
                                </OpenButton>
                                <DeleteButton onClick={onRemove}>
                                    Remove
                                </DeleteButton>
                            </PreviewContainer>
                        </AttachmentBox>
                        :
                        <FileUploadLabel htmlFor="file-upload">
                            <RoundDiv><Document /></RoundDiv>
                            <HiddenFileInput 
                                id="file-upload" 
                                type="file" 
                                accept="application/pdf"
                                onChange={handleFileUpload} 
                            />
                            <UploadInstruction>Click to Upload<br/>(Max. File size: 5 MB)</UploadInstruction>
                            {
                                errors.attachment &&
                                <span>{errors.attachment}</span>
                            }
                        </FileUploadLabel>
                    }
                </Section>
            </ApplicantContainer>
            {
                applicants.length > 0 && applicationPackage && 
                <GapDiv>
                    <PreviousButton onClick={()=>setIsFocus('Document Handling')}>Previous</PreviousButton>
                    <NextButton onClick={handleSubmit}>Review Application</NextButton>
                </GapDiv>
            }
        </>
    );
};

export default AddApplicantPlusPackage;
