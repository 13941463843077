import React, { useState, useMemo, useEffect } from "react";
import ChatChannel from "../agent-chat/agent-chat";
import PaymentInfo from "./payment-info/payment-info";
import PaymentBox from '../payment-box/payment-box.jsx'
import LeaseInfo from "./lease-info/lease-info";
import {
    Canvas,
    TabContentHeader,
    TabHolder,
    Tab,
    CanvasSmall,
    Container
} from './deal-detail-content.styles'


const DealDetail = ({deal, activateModal, updateDeal}) => {

    const [focusTab, setFocusTab] = useState('Lease Info')
    const [focusPayment, setFocusPayment] = useState({
        rentType: '',
        rentValue: {}
    });

    useEffect(() => {
        resetFocusPayment();
    },[focusTab,deal])

    const onTabClick = (value) => {
        setFocusTab(value)
    }

    const handleSelectedPayment = ( rentType, rentValue) => {
        if(focusPayment.rentType === rentType){
            resetFocusPayment();
        }else {
            setFocusPayment({
                rentType: rentType,
                rentValue: rentValue
            })
        }
    }

    const resetFocusPayment = () => {
        setFocusPayment({
            rentType: '',
            rentValue: {}
        })
    }

    // console.log(deal)

    const renderScreen = useMemo(() => {
        switch(focusTab){
            case 'Lease Info':
                return <LeaseInfo
                    applicants = {deal?.applicants}
                    guarantors = {deal?.guarantors}
                    leaseDue = {deal?.leaseDueDate}
                    leaseDoc = {deal?.leaseDoc}
                    agent = {deal?.agent}
                    setFocusTab = {setFocusTab}
                    activateModal = {activateModal}
                />
            case 'Payment Info':
                return <PaymentInfo
                    dealId = {deal?.id}
                    dealDeposits = {deal.dealMoveinDeposits}
                    amountDue = {deal?.amountDue}
                    amountPaid = {deal?.amountPaid}
                    handleSelectedPayment = {handleSelectedPayment}
                    focusPayment = {focusPayment}
                    updateDeal = {updateDeal}
                />
            case 'Chat':
                return <ChatChannel
                    groupId = {deal.externalGroupId}
                />
            default: 
                return <LeaseInfo
                    applicants = {deal?.applicants}
                    leaseDue = {deal?.leaseDueDate}
                    leaseDoc = {deal?.leaseDoc}
                    agent = {deal?.agent}
                    setFocusTab = {setFocusTab}
                    activateModal={activateModal}
                />
        }
    },[focusTab, deal, setFocusTab, focusPayment])

    const hasFocusPayment = focusPayment.rentType !== '';

    return (
        <Container>
            <Canvas showSmallCanvas={hasFocusPayment}>
                <TabContentHeader>    
                    <TabHolder>
                        <Tab 
                            onClick = {()=>onTabClick('Lease Info')} active = {focusTab === "Lease Info"}
                        >
                            Lease Info
                        </Tab>
                        <Tab 
                            onClick = {()=>onTabClick('Payment Info')} active = {focusTab === "Payment Info"}
                        >
                            Payment Info
                        </Tab>
                        <Tab 
                            onClick = {()=>onTabClick('Chat')} active = {focusTab === "Chat"}
                        >
                            Chat
                        </Tab>
                    </TabHolder>
                </TabContentHeader>
                {renderScreen}
            </Canvas>
            <CanvasSmall showSmallCanvas={hasFocusPayment}>
                <PaymentBox
                    focusPayment = {focusPayment}
                    updateDeal = {updateDeal}
                    dealId = {deal?.id}
                    onClose = {() => resetFocusPayment()}
                />
            </CanvasSmall>
        </Container>
    )
}

export default DealDetail