import React, { useState, useRef, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PopupForm from "../../components/popup-form/popup-form";
import CreateLeaseModality from "../../components/create-lease-modality/create-lease-modality";
import LoaderCard from "../../components/loading-cards/loading-card";
import { ApiContext } from "../../context/apiContext";
import { toast } from "react-toastify";
import RenewalsFilter from "../../components/renewals-filter/renewals-filter";
import RenewalCard from "../../components/renewals-card/renewals-card";
import RenewalDetail from "../../components/renewals-detail/renewals-detail";
import RenewalOffer from '../../components/renewal-offer-modality/renewal-offer-modality'

import { ContentSection, DetailContent } from "./renewals.pages.styles";

import renewalsData from './dummy-renewals.json'
import renewalFocus from './dummy-renewal-detail.json'
import RenewalApproval from "../../components/renewal-approval/renewal-approval";

const Renewals = ({ headerHeight }) => {

    const api = useContext(ApiContext);
    const { renewalId } = useParams();
    const navigate = useNavigate();

    const [renewals, setRenewals] = useState([]);
    const [focusRenewal, setFocusRenewal] = useState(null); // Focused renewal for detailed view
    const [query, setQuery] = useState("&status=Pending"); // Default filter
    const [isLoading, setIsLoading] = useState(true);

    const [isOfferModal, setIsOfferModal] = useState(false)
    const [isRenewalModal, setIsRenewalModal] = useState(false);
    const [isLeaseModalOpen, setIsLeaseModalOpen] = useState(false)

    const [isFirstOffer, setIsFirstOffer] = useState(false);
    const [modalRenewal, setModalRenewal] = useState({});


    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [forceFetch, setForceFetch] = useState(false);
    const observerRef = useRef();

    // Fetch all renewals data
    const fetchData = async (pageNumber) => {
        try {
            // let apiURL = `/api/renewals/all?page=${pageNumber}`;
            // if (query) apiURL += query;

            // const response = await api.get(apiURL);

            if (pageNumber === 1) {
                setRenewals(renewalsData.results)
                // setRenewals(response.results);
            } else {
                setRenewals((prev) => [...prev, ...renewalsData.results])
            //     setRenewals((prev) => [...prev, ...response.results]);
            }
            setHasMore(!!renewalsData.next)
            // setHasMore(!!response.next);
        } catch (error) {
            toast.error(`There was an error loading the data: ${error.message}`);
            setHasMore(false);
        } finally {
            setIsLoading(false);
            setForceFetch(false);
        }
    };

    // Fetch single renewal data
    const fetchSingleRenewal = async (id) => {
        try {
            // const data = await api.get(`/api/renewals/detail`, { renewalId: id });
            // console.log('check here')
            // setFocusRenewal(data);
            setFocusRenewal(renewalFocus)
        } catch (error) {
            toast.error(`There was an error loading the renewal: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    // Handle filters
    const handleFilter = (value) => {
        switch (value) {
            case "Pending":
                setQuery("&status=Pending");
                break;
            case "Under Negotiation":
                setQuery(
                    "&status__in=Initiated,Denied,PartiallyAccepted,ReplacementRequested,FormSigning"
                );
                break;
            case "Payment Pending":
                setQuery("&status=PendingPayments");
                break;
            case "Renewed":
                setQuery("&status=Renewed");
                break;
            case "Re-listed":
                setQuery("&status=Relisted");
                break;
            default:
                setQuery(null);
        }
        setPage(1);
        setRenewals([]); // Clear renewals list when changing filters
        fetchData(1); // Trigger data fetch directly
    };

    // Navigate to detailed view or list view
    const toggleView = (id) => {
        // console.log(id)
        if (id) {
            navigate(`/renewals/${id}`);
            fetchSingleRenewal(id);
        } else {
            navigate(`/renewals`);
            setFocusRenewal(null);
            if (renewals.length === 0) {
                fetchData(1); // Fetch all renewals if the list is empty
            }
        }
    };

    const initiateOffer = (data,flag) => {
        setModalRenewal(data);
        setIsOfferModal(true);
        setIsFirstOffer(flag);
    }

    const addOffer = (data) => {
        if(focusRenewal){
            setFocusRenewal(prev => ({
                ...prev,
                ...data
            }))
        }
    }

    const showRenewalModal = (data) => {
        setModalRenewal(data);
        if(data.applicationStatus === "Accepted"){
            setIsLeaseModalOpen(true)
        }else{
            setIsRenewalModal(true)
        }
    }

    const updateDeal = (data) => {
        setFocusRenewal(prev => ({
            ...prev,
            leases: {
                ...prev.leases,
                leaseDoc: data.leaseDoc,
                leaseDueDate: data.leaseDueDate
            }
        }))
    }

    const updateRenewal = (data) => {
        if(focusRenewal){
            setFocusRenewal(prev => ({
                ...prev,
                ...data
                // applicationStatus: data?.applicationStatus,
                // renewalStatus: data.renewalStatus,
                // leaseStartDate: data?.leaseStartDate,
                // leaseEndDate: data?.leaseEndDate,
                // payments: data?.payments,
                // latestUpdateDate: new Date(),
                // latestOfferPrice: data?.updatedRent,
                // dealId: data?.dealId
            }))
        }
        setRenewals(prev =>  
            prev.map(renewal => renewal.renewalId === data.renewalId ? ({
                ...renewal,
                ...data
            })
            :
            renewal
        ))
    }


    const handleRenewalAction = async (action, renewalId) => {
        try {
            const data = await api.post(`/api/renewals/${action}`, { renewalId });
            if (action!=="Reminder") {
                updateRenewal(data);
            }
            toast.info(`${action.replace('-', ' ')} successful`);
        } catch (error) {
            toast.error(`There was an issue with ${action}: ${error}`);
        }
    };

    // Infinite scrolling
    useEffect(() => {
        const loadMoreEntries = (entries) => {
            const target = entries[0];
            if (target.isIntersecting && hasMore) {
                setPage((prev) => prev + 1);
            }
        };

        const observer = new IntersectionObserver(loadMoreEntries, {
            root: null,
            rootMargin: "20px",
            threshold: 1.0,
        });

        if (observerRef.current) observer.observe(observerRef.current);

        return () => {
            if (observerRef.current) observer.unobserve(observerRef.current);
        };
    }, [hasMore]);

    // Fetch data on page change
    useEffect(() => {
        if (page > 1 || forceFetch) fetchData(page);
    }, [page, forceFetch]);

    // Initial data fetch
    useEffect(() => {
        if (renewalId) {
            setIsLoading(true);
            fetchSingleRenewal(renewalId);
        } else {
            fetchData(1);
        }
    }, [renewalId]);

    // console.log(focusRenewal)

    return (
        <>
            {
                !focusRenewal ?
                <>
                    <RenewalsFilter handleFilter={handleFilter} />
                    {
                        isLoading ? (
                            <ContentSection>
                                <LoaderCard size="large" />
                            </ContentSection>
                        ) : (
                            <ContentSection headerHeight={headerHeight}>
                                {
                                    renewals.map((renewal) => (
                                        <RenewalCard
                                            id = {renewal.id}
                                            renewal = {renewal}
                                            handleClick = {toggleView}
                                            initiateOffer = {() => initiateOffer(renewal, true)}
                                            showRenewalModal = {showRenewalModal}
                                            handleRenewalAction = {handleRenewalAction}
                                        />
                                    ))
                                }
                                <div ref={observerRef} style={{ height: "5px" }} />
                            </ContentSection>
                        )
                    }
                </>
                :
                <DetailContent headerHeight = {headerHeight}>
                    <RenewalDetail
                        focusRenewal={focusRenewal.renewalDetail}
                        currentIndex = {focusRenewal.pagination.currentIndex}
                        totalRenewals = {focusRenewal.pagination.totalRenewals}
                        nextRenewalId = {focusRenewal.pagination.nextRenewalId}
                        previousRenewalId = {focusRenewal.pagination.previousRenewalId}
                        handleClick={toggleView}
                        initiateOffer = {initiateOffer}
                        setFocusRenewal = {setFocusRenewal}
                        showRenewalModal= {showRenewalModal}
                        setIsLeaseModalOpen = {setIsLeaseModalOpen}
                        handleRenewalAction = {handleRenewalAction}
                    />
                </DetailContent>
            }
            {
                isOfferModal &&
                <RenewalOffer
                    renewalId = {modalRenewal.renewalId}
                    offerPrice = {modalRenewal.latestOfferPrice}
                    renewalProperty = {modalRenewal.property}
                    addOffer = {addOffer}
                    onClose = {() => setIsOfferModal(false)}
                    firstOffer = {isFirstOffer}
                />
            }
            {
                isRenewalModal && 
                <RenewalApproval
                    modalRenewal = {modalRenewal}
                    offerPrice = {modalRenewal.latestOfferPrice} 
                    onClose = {() => setIsRenewalModal()}
                    setIsLeaseModalOpen = {setIsLeaseModalOpen}
                    updateRenewal = {updateRenewal}
                />
            }
            {
                isLeaseModalOpen &&
                (
                    modalRenewal?.leases?.leaseDoc && modalRenewal?.leases?.leaseDoc !== "" && modalRenewal?.leases?.leaseDoc !== undefined?
                    <PopupForm
                        showPopupUrl={modalRenewal?.leases?.leaseDoc}
                        handleClose={() => setIsLeaseModalOpen(false)}
                    />
                    :
                    <CreateLeaseModality
                        onClose={() => setIsLeaseModalOpen(false)}
                        modalId={modalRenewal.dealId}
                        updateDeal={updateDeal}
                    />
                )
            }
        </>
    );
};

export default Renewals;
