import styled from "styled-components";

export const DropdownContainer = styled.div`
  position: relative;
  width: ${(props) => props.width || "250px"};
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const StyledInput = styled.input`
  width: -webkit-fill-available;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  padding: 10px;
  padding-left: 36px;
  padding-right: 28px;
  font-size: 0.875rem;
  outline: none;
  &:focus {
    border-color: #3b82f6;
    box-shadow: 0 0 0 1px #3b82f6;
  }
  height: ${(props) => props.height || "48px"};
`;

export const SearchIcon = styled.div`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  height: 16px;
  width: 16px;
  color: #9ca3af;
  svg {
    fill: currentColor;
  }
`;

export const CloseIcon = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  height: 16px;
  width: 16px;
  color: #9ca3af;
  cursor: pointer;
  svg {
    fill: currentColor;
  }
`;

export const DropdownList = styled.div`
  position: absolute;
  z-index: 10;
  margin-top: 4px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const DropdownItemsContainer = styled.div`
  max-height: ${(props) => props.maxHeight || "288px"};
  overflow-y: auto;
`;

export const DropdownItem = styled.div`
  padding: 12px;
  font-size: 0.875rem;
  cursor: pointer;
  border-radius: 8px;
  background-color: ${(props) => (props.isSelected ? "#d1d5db" : "white")};
  &:hover {
    background-color: #f3f4f6;
  }
`;

export const LoadingMessage = styled.div`
  padding: 12px;
  font-size: 0.875rem;
  text-align: center;
  color: #6b7280;
`;

export const NoOptionsMessage = styled.div`
  padding: 12px;
  font-size: 0.875rem;
  text-align: center;
  color: #6b7280;
`;
