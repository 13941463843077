import React, { useContext, useState } from "react";
import NewPayment from "../../new-payment-modality/new-payment-modality";
import { formatCurrency } from "../../../utilities/utility";
import { ApiContext } from "../../../context/apiContext";
import { UserContext } from "../../../context/userContext";

import {
    Container,
    FlexDivColumn,
    Heading,
    Content,
    PreviousButton,
    NextButton,
    ButtonDiv,
    FormTextArea,
} from '../shared-styles'

import {
    Payment,
    PaymentContainer,
    PaymentTitle,
    AddButton,
    BrokerInput,
    DateWrapper,
    CheckBoxLabel
} from './approve-modality.styles'

const ApproveModality = ({applicationId, setActiveTab, updateApplication, offerMoveinDeposits, depositPaidType, depositPaid, leaseDates}) => {
    const api = useContext(ApiContext)
    const {user} = useContext(UserContext)
    const [isApproved, setIsApproved] = useState(false)
    const [approvalText, setApprovalText] = useState('')
    const [deposits, setDeposits] = useState(() => 
        Object.entries(offerMoveinDeposits || {}).reduce((acc, [key, value]) => ({
            ...acc,
            [key]: {
                amount: value,
                dueDate: null
            }
        }), {})
    );
    const [dates, setDates] = useState({
        from: leaseDates?.from, to: leaseDates?.to
    })
    const [brokerFee, setBrokerFee] = useState(null);
    const [sendDepositLink, setSendDepositLink] = useState(true);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newPayment, setNewPayment] = useState({ type: '', amount: 0, dueDate: '' });

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const handleAddPayment = () => {
        setDeposits(currentDeposits => ({
            ...currentDeposits,
            [newPayment.type]: {
                amount: Number(newPayment.amount),
                dueDate: newPayment.dueDate
            }
        }));

        // Reset the form and close the modal
        setNewPayment({ type: '', amount: 0, dueDate: '' });
        handleCloseModal();
    };

    const handleDateChange = (key, value) => {
        setDeposits(currentDeposits => ({
            ...currentDeposits,
            [key]: {
                ...currentDeposits[key],
                dueDate: value
            }
        }));
    };

    const handleAmountChange = (key, value) => {
        setDeposits(currentDeposits => ({
            ...currentDeposits,
            [key]: {
                ...currentDeposits[key],
                amount: parseFloat(value)
            }
        }));
    };

    const onApprove = async () => {
        // Check if any due dates are empty
        // console.log(deposits)
        const hasEmptyDueDates = Object.entries(deposits)
            .filter(([key, value]) => (
                key !== "Total" && key !== "Deposit On Account" & key !== 'Application Fee'  &&  key !== "Broker Fee") 
                && !(depositPaid && key === depositPaidType) && value.amount !== 0)
            .some(([_, value]) => !value.dueDate);
    
        if (hasEmptyDueDates) {
            alert("Please ensure all payments have a due date before approving.");
            return; // Prevent the rest of the function from executing
        }

        if(!brokerFee){
            user?.clientType === "property_management" ? 
            alert("Please ensure you have added the broker fee amount paid to agent. This can be 0 if no fee is to be paid.")
            :
            alert("Please ensure you have added the co-broke fee amount received from the agent. This can be 0 if no fee is to be collected!")

            return;
        }

        setIsApproved(true)
        const payload = {
            applicationId: applicationId,
            depositTerms: deposits,
            approvalText: approvalText,
            coBroke: brokerFee,
            isPaidToAgent: user?.clientType === "property_management",
            leaseStart: dates.from,
            leaseEnd: dates.to,
            sendDepositLink: sendDepositLink
        };
    
        try {
            // Utilize the api context for making the PUT request
            const data = await api.put(`/api/application/approve`, payload);
            updateApplication(data); // Assuming this updates your component state or context
        } catch (error) {
            console.error('Error during the fetch operation:', error);
        }
    };
    
    

    return (
        <Container height = {180}>
            <Content>
                <FlexDivColumn>
                    <div style={{display:'flex', gap: '10px', alignItems: 'center'}}>
                        <Heading>Payment Breakdown</Heading>
                        <AddButton onClick={handleOpenModal}>Add +</AddButton>
                    </div>
                    <NewPayment 
                        isOpen={isModalOpen} 
                        onClose={handleCloseModal}
                        setNewPayment={setNewPayment}
                        handleAddPayment = {handleAddPayment}
                        newPayment={newPayment}
                    /> 
                    <PaymentContainer>
                        <PaymentTitle>
                            <div>Rent Type</div>
                            <div>Due Date</div>
                            <div>Amount</div>
                        </PaymentTitle>
                        {
                            Object.entries(deposits)
                            .filter(([key, value]) => key !== "Deposit On Account" && key !== "Application Fee" &&  key !== "Broker Fee" && value.amount !== 0)
                            .map(([key,value]) => (
                                <Payment key = {key}>
                                    <div>{key} {key === depositPaidType && depositPaid && <span>Paid</span>}</div>
                                    {
                                        !(key === depositPaidType && depositPaid) ?
                                        <input
                                            type="date" 
                                            id={`dueDate-${key}`} 
                                            name={`Due Date`}
                                            value={value.dueDate}
                                            onChange={(e) => handleDateChange(key,e.target.value)}
                                        />
                                        :
                                        <></>
                                    }
                                    {/* <div>{formatCurrency(key === "Deposit on Account" ? -Math.abs(value.amount) : value.amount)}</div> */}
                                    <input
                                        type = "number"
                                        id={key} 
                                        value={parseFloat(value.amount)}
                                        name={`Amount`}
                                        onChange={(e) => handleAmountChange(key,e.target.value)}
                                    />
                                </Payment>
                            ))
                        }
                        <Payment style={{borderBottom:'unset'}}>
                            <div><strong>Total</strong></div>
                            <div>
                                <strong>
                                {
                                    formatCurrency(
                                        Object.entries(deposits).filter(([key, _]) => key !== "Deposit On Account" && key !== "Application Fee"  &&  key !== "Broker Fee" )
                                        .reduce((accumulator, [key, value]) => {
                                            return accumulator + parseFloat(value.amount);
                                        }, 0)
                                    )
                                }
                                </strong>
                            </div>
                        </Payment>
                    </PaymentContainer>
                    <CheckBoxLabel>
                        <label style ={{display:'flex', fontSize:'14px', gap: "5px", fontWeight:"500", alignItems:'center'}} >
                            <input
                                type="checkbox"
                                checked={sendDepositLink}
                                onChange={() => setSendDepositLink(prev => !prev)}
                            />Do you want to use <strong>DepositLink</strong> to collect the payments?
                        </label>
                    </CheckBoxLabel>
                </FlexDivColumn>
                <FlexDivColumn>
                    <Heading>
                        {user?.clientType === "property_management"? "How much broker fee do you pay to the agent?":"What is amount of co-broke you collect?"} 
                    </Heading>
                    <BrokerInput
                        type = "number"
                        id ="Brokerfee"
                        value={parseFloat(brokerFee)}
                        name={`Broker Fee`}
                        onChange={(e) => setBrokerFee(e.target.value)}
                    />
                </FlexDivColumn>
                <FlexDivColumn>
                    <Heading>
                        Enter lease start and end Dates 
                    </Heading>
                    <DateWrapper>
                        <label>
                            <div>Lease Start: </div>
                            <input
                                type="date"
                                value={dates.from}
                                onChange={(e) => setDates(prev => ({...prev, from: e.target.value}))}
                                placeholder="Start Date"
                            />
                        </label>
                        <label>
                            <div>Lease End: </div>
                            <input
                                type="date"
                                value={dates.to}
                                onChange={(e) => setDates(prev => ({...prev, to: e.target.value}))}
                                placeholder="End Date"
                            />
                        </label>
                    </DateWrapper>
                </FlexDivColumn>
                <FlexDivColumn>
                    <Heading>Write an approval message</Heading>
                    <FormTextArea
                        value={approvalText}
                        onChange={(e) => setApprovalText(e.target.value)}
                        placeholder="Add remarks"
                    />
                </FlexDivColumn>
            </Content>
            <ButtonDiv>
                {
                    <PreviousButton onClick = {() => setActiveTab('Review')}>Previous</PreviousButton>
                }
                {
                    !isApproved && <NextButton onClick={onApprove}>Send Approval</NextButton>
                }
            </ButtonDiv>
        </Container>
    )
}

export default ApproveModality