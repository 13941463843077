import React from 'react';
import { formatDate, formatCurrency } from '../../utilities/utility';
import {
    PropertyImg,
    PropertyAddress,
    PropertyPrice,
    FlexDivGapAlign,
    FlexDivColumn,
    FlexDivGapLarge,
} from './property-info.styles';
import { ReactComponent as Wallet } from '../../assets/Wallet.svg';
import { ReactComponent as Arrow } from '../../assets/Arrow - Price change.svg';
import { ReactComponent as ArrowDown } from '../../assets/Arrow - Price decrease.svg';
import { ReactComponent as Truck } from '../../assets/bi_truck.svg';

const PropertyInfo = ({ property, offerPrice }) => {
    
    const { formattedDate } = formatDate(new Date(property?.moveIn || property?.moveOut));

    return (
        <FlexDivGapAlign>
            <PropertyImg src={property.imageUrl || "https://via.placeholder.com/80"} />
            <FlexDivColumn>
                <PropertyAddress>{property.address}</PropertyAddress>
                <FlexDivGapLarge>
                    <PropertyPrice priceUp={offerPrice > property.price} priceDown = {offerPrice < property.price}>
                    {
                        offerPrice > property.price ? (
                        <>
                            <Wallet stroke="#1CCB00" />{formatCurrency(offerPrice)}<Arrow />
                        </>
                        ) :
                        offerPrice < property.price ? (
                        <>
                            <Wallet stroke="#FF0000" />{formatCurrency(offerPrice)}<ArrowDown/>
                        </>
                        )
                        :
                        (
                        <>
                            <Wallet stroke="#747474" />{formatCurrency(offerPrice)}
                        </>
                        )
                    }
                    </PropertyPrice>
                    <PropertyPrice>
                        <Truck fill="#747474" />
                        {formattedDate}
                    </PropertyPrice>
                </FlexDivGapLarge>
            </FlexDivColumn>
        </FlexDivGapAlign>
    );
}

export default PropertyInfo;
