import React, { useState, useCallback, useContext, useEffect } from "react";
import { debounce, formatCurrency } from '../../../utilities/utility';
import { toast } from 'react-toastify';
import { ApiContext } from "../../../context/apiContext";
import { UserContext } from "../../../context/userContext";

import { 
    Keys,
    KeysTop,
    InfoContainer,
    GridContainer,
    Values,
    StatusBadge,
    BulletIcon,
    ColorSpan,
    MoveInPayments,
    Vacancy,
    DepositOptions,
    ValueInput,
    // DepositItem,
    ValueText
} from '../shared-modality.styles'

import { ReactComponent as Bed } from '../../../assets/bed.svg';
import { ReactComponent as Bath } from '../../../assets/bath.svg';
import { ReactComponent as Star } from '../../../assets/star.svg'


const PropertyInfoModality = ({property, setFocusProperty}) => {
    const api = useContext(ApiContext)
    const { user } = useContext(UserContext)
    console.log(user)
    const [moveInDeposits, setMoveInDeposits] = useState(property?.propertyInfo?.moveinDeposits); 
    const [keyValue, setKeyValue] = useState(property?.lockbox)


    const calculateSelection = (amount) => {
        let category;
                        
        // Determine the category based on amount
        if (amount === property.propertyInfo.price) {
            category = '1 month';
        } else if (amount === property.propertyInfo.price / 2) {
            category = 'Half month';
        } else if (!amount || amount === 0) {
            category = 'NA';
        } else {
            category = 'Other';
            // For 'Other', the amount is already custom, so no preset selectValue is needed
        }

        return category;
    }

    const [otherSelections, setOtherSelections] = useState({});
    const [id, setId] = useState(property?.propertyInfo?.id)

    useEffect(() => {
        setMoveInDeposits(property?.propertyInfo?.moveinDeposits)
        setKeyValue(property?.lockbox)
        setId(property?.propertyInfo?.id)
        setOtherSelections(() => {
            const initialSelections = {};
            // Assuming moveInDeposits is available and structured correctly
            Object.entries(property?.propertyInfo?.moveinDeposits || {}).forEach(([type,amount]) => {
                if(calculateSelection(amount) !== 'Other'){
                    initialSelections[type] = false; // Initially, none are set to "Other"
                }else{
                    initialSelections[type] = true; // Initially, none are set to "Other"
                }
            });
            return initialSelections;
        })
    },[property])

    const handleVacancyChange = async (event) => {
        try {
            const data = await api.put(`/api/listing/internal/vacancy/update`, {
                propertyId: id,
                isVacant: event.target.value.trim() || null
            })
            setFocusProperty(prev => ({...prev, isVacant: data.value}))
            // updateProperty(data,'isVacant')
            toast.success('Unit Vacancy Changed!')   
        } catch (error) {
            console.error(error); // It's good to log the error for debugging purposes
            toast.error('Could not update the vacancy status at this time')
        }
    }

    const handleAllowTourChange = async (event) => {
        try {
            const data = await api.put(`/api/listing/internal/allow_tour`, {
                propertyId: id,
                allowTour: event.target.value.trim() || null
            })
            setFocusProperty(prev => ({...prev, allowTour: data.value}))
            // updateProperty(data,'isVacant')
            toast.success('Tour Status Changed!')   
        } catch (error) {
            console.error(error); // It's good to log the error for debugging purposes
            toast.error('Could not update the tour status at this time')
        }
    }

    const handleKey = (event) => {
        let keyValue = event.target.value;
        setKeyValue(keyValue);
        handleKeyChange(keyValue)
    }

    const handleKeyChange =  useCallback(debounce(async (value) => {
        try {
            const data = await api.put(`/api/listing/internal/keyinfo/update`, {
                propertyId: id,
                value: value
            })
                        // updateProperty(data, 'lockbox')
            toast.success('Key Info Updated!')
        } catch (error) {
            console.error(error); // It's good to log the error for debugging purposes
            toast.error('Could not update the key info at this time')
        }
    }, 500),[id])

    const handleDepositChange = (value, type) => {
        const category = calculateSelection(parseFloat(value.toString().replace(/[\$,]/g, '')));
        // console.log(category)
        if(category !== 'Other') handleOptionSelect(type,false)

        const updatedDeposits = { ...moveInDeposits, [type]: parseFloat(value.toString().replace(/[^\d.]/g, ''),10) };
        setMoveInDeposits(updatedDeposits);
        handleDepositChangeDebounced(value, type);
    }

    const handleOptionSelect = (type, isOther) => {
        setOtherSelections(prev => ({ ...prev, [type]: isOther }));
    };

    // const handleDepositClick = (option, type) => {
    //     let numericValue;
    //     switch(option) {
    //         case '1 month':
    //             numericValue = property.propertyInfo.price;
    //             break;
    //         case 'Half month':
    //             numericValue = property.propertyInfo.price / 2;
    //             break;
    //         case 'NA':
    //             numericValue = 0;
    //             break;   
    //     }
    //     const updatedDeposits = { ...moveInDeposits, [type]: numericValue };
    //     setMoveInDeposits(updatedDeposits);
    //     handleOptionSelect(type, false);
    //     handleDepositChangeDebounced(numericValue, type);
    // }

    const handleDepositChangeDebounced = useCallback(debounce(async (value, type) => {
        try {
            const numericValue = parseFloat(value.toString().replace(/[\$,]/g, ''));
            const data = await api.put(`/api/listing/internal/deposit/update`, {
                propertyId: id,
                value: numericValue,
                type: type
            })
            // updateProperty(data, data.type)
            toast.success(`${type} Updated!`);
        } catch (error) {
            console.error(error); // It's good to log the error for debugging purposes
            toast.error(`Could not update the ${type} at this time`)
        }
    }, 500), [id]);


    return (
        <InfoContainer>
            <GridContainer>   
                <Keys>Status</Keys>
                <StatusBadge status={property.propertyInfo.status}>
                    <BulletIcon />
                    {property.propertyInfo.status}
                </StatusBadge>
                <Keys>Rent</Keys>
                <Values>{formatCurrency(property.propertyInfo.price)}</Values>
                <Keys>Beds</Keys>
                <Values><Bed stroke='#7e7e7e'/> {property.propertyInfo.bedrooms}</Values>
                <Keys>Bathrooms</Keys>
                <Values><Bath /> {property.propertyInfo.bathrooms}</Values>
                {
                    moveInDeposits &&
                    <KeysTop>Move In Payments</KeysTop>
                }
                {
                    moveInDeposits &&
                    <MoveInPayments>
                        {
                            Object.entries(moveInDeposits).map(([type, amount]) => {
                                return <div style = {{display: 'flex', flexDirection: 'column', gap:'3px'}}>
                                    <Keys>{type}</Keys>
                                    <DepositOptions>
                                    {
                                    //     ['1 month', 'Half month', 'NA'].map(value => 
                                    //         <DepositItem
                                    //             selected = {calculateSelection(amount) === value && !otherSelections[type]}
                                    //             onClick={() => handleDepositClick(value, type) }
                                    //         >
                                    //             {value}
                                    //         </DepositItem>
                                    //     )
                                    // }
                                    // {
                                    //     <DepositItem
                                    //         selected = {otherSelections[type]}
                                    //         onClick={() => handleOptionSelect(type, true)}
                                    //     >
                                    //         Other:  
                                            // {
                                                // (calculateSelection(amount) === "Other" || otherSelections[type])  &&
                                        <ValueInput
                                            type = "text"
                                            value = {formatCurrency(amount) || 0}
                                            placeholder="Enter Amount"
                                            onChange = {(e) => handleDepositChange(e.target.value, type) }
                                        />
                                            // }
                                        // </DepositItem>                                          
                                    }
                                    </DepositOptions>
                                </div>
                            })
                        }
                    </MoveInPayments>
                }
                <Keys>Key Info</Keys>
                {
                    user?.isDefaultLockboxEnabled ? 
                    <Values>{keyValue}</Values>
                    :
                    <ValueText
                        type = "text"
                        value = {keyValue || ''}
                        onChange={handleKey}
                        rows={3}
                    />
                }
                
                <Keys>Vacancy</Keys>
                <Vacancy isVacant = {property.isVacant} onChange={handleVacancyChange} value={property.isVacant || false}>
                    <option value = {true}>Vacant</option>
                    <option value = {false}>Not Vacant</option>
                </Vacancy>
                <Keys>Allow Tours</Keys>
                <Vacancy isVacant = {property.allowTour} onChange={handleAllowTourChange} value={property.allowTour || false}>
                    <option value = {true}>Yes</option>
                    <option value = {false}>No</option>
                </Vacancy>
                <Keys>Showings</Keys>
                <Values>{property.completedShowings}<ColorSpan>/{property.numShowings} Done</ColorSpan></Values>
                <Keys>Ratings</Keys>
                <Values><Star/>{property.avgRating} ({property.numRatings} {property.numRatings !== 1? 'Reviews':'Review'})</Values>
            </GridContainer>
        </InfoContainer>
    )
}

export default PropertyInfoModality