import React from "react";
import { formatCurrency } from "../../../utilities/utility";
import moment from "moment";
import {
    Canvas, FlexDivColumn, Heading, FlexDiv, ActionButton, Card, Header, Title, Date, Content, Rent, Description, Validity, Attachment
} from './offers.styles'



const Offers = ({ initiateOffer, focusRenewal, offers}) => {
    return (
        <Canvas>
            <FlexDiv>
                <Heading>Offers</Heading>
                <ActionButton onClick = {() => initiateOffer(focusRenewal, false)}>New Offer</ActionButton>
            </FlexDiv>
            <FlexDivColumn>
                {
                    offers.map((offer, index) => 
                        <Card key={index}>
                            <Header>
                                <div style = {{display:'flex', flexDirection:'column', gap: '5px'}}>
                                    <Title>{offer.title}</Title>
                                    <Rent><strong>Rent:</strong> {formatCurrency(offer.updateRent)}</Rent>
                                </div>
                                <Date>Update: {moment(offer.updateDate).format('MM-DD-YYYY')}</Date>
                            </Header>
                            <Content>
                                <Description>{offer.description}</Description>
                                
                                <Validity><strong>Valid Till:</strong> {moment(offer.Validity).format('MM-DD-YYYY')}</Validity>
                                {offer.attachment && (
                                    <Attachment href={offer.attachment} target="_blank" rel="noopener noreferrer">
                                         View Attachment
                                    </Attachment>
                                )}
                            </Content>
                        </Card>
                    )
                }
            </FlexDivColumn>
        </Canvas>
    )
}

export default Offers