import React, { createContext, useCallback, useEffect, useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken'));
    const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken'));

    const login = useCallback(async (username, password) => {
        try {
            const response = await fetch('/api/user/token/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            if (!response.ok) {
                throw new Error('Login failed');
            }

            const data = await response.json();
            setUser(data);
            setAccessToken(data.access);
            setRefreshToken(data.refresh);

            localStorage.setItem('accessToken', data.access);
            localStorage.setItem('refreshToken', data.refresh);

            if (data.userType === 'landlord') {
                navigate('/leases');
            } else {
                navigate('/showings');
            }
        } catch (error) {
            toast.error('Login failed. Please check your credentials.');
            return false;
        }
    }, [navigate]);

    const logout = useCallback(() => {
        setUser(null);
        setAccessToken(null);
        setRefreshToken(null);

        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');

        navigate('/');
    }, [navigate]);

    const refreshTokens = useCallback(async () => {
        if (!refreshToken) {
            logout();
            return;
        }

        try {
            const response = await fetch('/api/user/token/refresh/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ refresh: refreshToken }),
            });

            if (!response.ok) {
                logout();
                return;
            }

            const data = await response.json();
            setAccessToken(data.access);
            setRefreshToken(data.refresh);

            localStorage.setItem('accessToken', data.access);
            localStorage.setItem('refreshToken', data.refresh);
        } catch (error) {
            toast.error(`Refresh token error: ${error}`);
            logout();
        }
    }, [refreshToken, logout]);

    const verifyToken = useCallback(async () => {
        if (!accessToken) {
            navigate('/');
            return;
        }

        try {
            const response = await fetch('/api/user/profile_details/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });

            if (response.ok) {
                const userData = await response.json();
                setUser(userData);

                if (location.pathname === '/') {
                    if (userData.userType === 'landlord') {
                        navigate('/leases');
                    } else {
                        navigate('/showings');
                    }
                }
            } else {
                logout();
            }
        } catch (error) {
            toast.error(`Error verifying token: ${error}`);
            logout();
        }
    }, [accessToken, location.pathname, navigate, logout]);

    // On app initialization, verify token
    useEffect(() => {
        if (accessToken) {
            verifyToken();
        }
    }, [accessToken, verifyToken]);

    // Periodic refresh of tokens
    useEffect(() => {
        const interval = setInterval(() => {
            refreshTokens();
        }, 1000 * 60 * 15); // Refresh every 15 minutes
        return () => clearInterval(interval);
    }, [refreshTokens]);

    const value = useMemo(() => ({
        user,
        accessToken,
        login,
        refreshTokens,
        verifyToken,
        logout,
    }), [user, accessToken, login, refreshTokens, verifyToken, logout]);

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
