import { ModalOverlay, ModalWrapper } from "./applicant-modality.styles.js";
import BaseApplicantForm from "./base-applicant-form.jsx";

const ApplicantModal = ({
    applicant,
    applicantDocuments,
    setApplicant,
    setApplicantDocuments,
    onClose,
    handleSave,
    isOpen,
    mode,
    applicationPackageState
}) => {
    return (
        <ModalOverlay onClick={onClose}>
            <ModalWrapper isOpen={isOpen} onClick={(e) => e.stopPropagation()}>
                <BaseApplicantForm
                    applicant={applicant}
                    applicantDocuments={applicantDocuments}
                    setApplicant={setApplicant}
                    setApplicantDocuments={setApplicantDocuments}
                    handleSave={handleSave}
                    mode = {mode}
                    applicationPackageState = {applicationPackageState}
                    onClose = {onClose}
                />
            </ModalWrapper>
        </ModalOverlay>
    );
};

export default ApplicantModal;
