import React, { useState, useEffect, useContext } from 'react';
import InfoModality from './info-modality/info-modality';
import ReviewList from './feedback-modality/feedback-modality';
import ConfirmModality from './confirm-modality/confirm-modality';
import OtherModality from './other-modality/other-modality';
import PropertyNotes from './notes-modality/notes-modality';
import TenantModality from './tenant-modality/tenant-modality';
import moment from 'moment'
import { ApiContext } from '../../context/apiContext';
import {
    ModalOverlay,
    ModalWrapper,
    Header,
    CloseButton,
    Title,
    Content,
    Tab,
    FlexDiv,
    NavigationButton,
    SpaceDiv,
    PropertyImg,
    TextNav,
    FlexDivColumn,
    HeaderSpan,
    TabHolder
} from './showing-modality.styles'; // Adjust the import path as needed
import { ReactComponent as Apps } from '../../assets/apps.svg'
import { ReactComponent as Truck } from '../../assets/bi_truck.svg'


const ShowingModal = ({ isOpen, onClose, focusId, setFocusId, activeTab, setActiveTab, showingDataIds, updateShowing }) => {
    
    const [showingRequest, setShowingRequest] = useState()
    const api = useContext(ApiContext); 

    // Handling of modality opening and closing
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await api.get(`/api/showing/internal/detail`,{showingId: focusId}); // todo: change to detail
                setShowingRequest(data); //Todo: Remove applications
            } catch (error) {
                console.error('Fetch error:', error);
            }
        };
    
        fetchData();

        // return  () => console.log('I am unmounting')
         
    }, [focusId]);

    const handleClose = () => {
        onClose(); // This will change the isOpen prop
    };

    // Offset the focus request based on the navigation icon
    const offsetShowing = (offset) => {
        const index = showingDataIds.findIndex(id => id === showingRequest.id)
        console.log(index, offset, index+offset > showingDataIds.length - 1, index + offset < 0, index + offset)
        if(index + offset > showingDataIds.length - 1) {
            const offsetRequest = showingDataIds[0];
            console.log(offsetRequest)
            setFocusId(offsetRequest)
            window.history.pushState({}, '', `/showings/${offsetRequest}`);
        }else if (index + offset < 0){
            const offsetRequest = showingDataIds[showingDataIds.length - 1];
            console.log(offsetRequest)
            setFocusId(offsetRequest)
            window.history.pushState({}, '', `/showings/${offsetRequest}`);
        }else {
            const offsetRequest = showingDataIds[index + offset];
            console.log(offsetRequest)
            setFocusId(offsetRequest)
            window.history.pushState({}, '', `/showings/${offsetRequest}`);
        }
    }

    if (!showingRequest?.id) return null;

    return (
        <ModalOverlay onClick={handleClose}>
            <ModalWrapper 
                isOpen={isOpen}
                onClick={(e) => e.stopPropagation()}
            >
                <Header>
                    {
                        activeTab === 'confirm' || activeTab === 'other' ?
                        <SpaceDiv>
                            <FlexDiv>
                                <NavigationButton onClick={() => setActiveTab('info')}>{"<"}</NavigationButton>
                                <TextNav>Appointment Details/
                                    <span style={{color: 'var(--Greyish-Black, #323232)'}}>{activeTab === 'other' ? 'Intervene Appointment':'Confirm Appointment'}</span>
                                </TextNav>
                            </FlexDiv>
                        </SpaceDiv>
                        :
                        <SpaceDiv>
                            <FlexDiv>
                                <CloseButton onClick={handleClose}>X</CloseButton>
                                <span style={{fontSize:"14px"}}>Close</span>
                            </FlexDiv>
                            <FlexDiv>
                                <NavigationButton onClick={() => offsetShowing(-1)}>{"<"}</NavigationButton>
                                <span style={{fontSize:"14px"}}>{showingDataIds.findIndex(id => id === showingRequest.id) + 1}/{showingDataIds.length}</span>
                                <NavigationButton onClick={() => offsetShowing(1)}>{">"}</NavigationButton>
                            </FlexDiv>
                        </SpaceDiv>
                    }
                    <FlexDiv>
                        <PropertyImg src={showingRequest.property.imageUrl}/>
                        <FlexDivColumn>
                            <Title>{showingRequest.property.address}</Title>
                            <FlexDiv style={{gap:'20px'}}>
                            <HeaderSpan style={{color:'#747474'}}>
                                <Truck fill='#525252' style={{verticalAlign: 'bottom', marginRight: '5px'}}/>{moment(showingRequest.property.moveIn, "MM-DD-YYYY").format('MMM DD, yyyy')}
                            </HeaderSpan>
                            { 
                                showingRequest.numApps && 
                                <HeaderSpan style={{color:'#E45A5C'}}>
                                    <Apps stroke="#E45A5C" style={{verticalAlign: 'bottom', marginRight: '5px'}}/>{showingRequest.numApps} Apps
                                </HeaderSpan>
                            }
                            </FlexDiv>
                        </FlexDivColumn>
                    </FlexDiv>
                </Header>
                {
                    activeTab !== 'confirm' && activeTab !== 'other' && 
                    <TabHolder>
                        <Tab onClick={() => setActiveTab('info')} active={activeTab === 'info'}>Info</Tab>
                        <Tab onClick={() => setActiveTab('notes')} active={activeTab === 'notes'}>Notes</Tab>
                        <Tab onClick={() => setActiveTab('feedback')} active={activeTab === 'feedback'}>Feedback</Tab>
                        <Tab onClick={() => setActiveTab('tenant')} active={activeTab === 'tenant'}>Tenants</Tab>
                    </TabHolder>
                }
                <Content>
                    {activeTab === 'info' && <InfoModality showingData = {showingRequest} setActiveTab = {setActiveTab}/>}
                    {activeTab === 'notes' && <PropertyNotes showingData = {showingRequest}/>}
                    {activeTab === 'tenant' && <TenantModality tenantInfo={showingRequest.tenantInfo}/>}
                    {activeTab === 'feedback' && <ReviewList feedbackData = {showingRequest.allFeedback} agentData = {showingRequest.agent}/>}
                    {activeTab === 'confirm' && <ConfirmModality showingData={showingRequest} updateShowing={updateShowing}/>}
                    {activeTab === 'other' && <OtherModality showingData={showingRequest} updateShowing={updateShowing}/>}
                </Content>
            </ModalWrapper>
        </ModalOverlay>
    );
};

export default ShowingModal;
