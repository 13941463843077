export const formatDate = (dateObj) => {

    // Extract date parts
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const formattedDate = dateObj.toLocaleDateString('en-US', options);

    // Extract the hours and minutes
    let hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();

    // Convert 24-hour time to 12-hour time format
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Format the time parts, ensuring two digits
    const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;

    return {formattedDate, formattedTime}
}

// export function generateNextDates() {
//     const dates = [];
//     for (let i = 0; i < 9; i++) {
//         let date = new Date();
//         date.setDate(date.getDate() + i);
//         dates.push(date);
//     }
//     return dates;
// }

export function generateNextDates(isVacant = false, is24window = false) {
    const dates = [];
    const startOffset = isVacant ? 0 : is24window? 1:2; // If isVacant is true, start from the next day.
    for (let i = 0; i < 9; i++) {
        let date = new Date();
        date.setDate(date.getDate() + i + startOffset); // Adjust the start date based on isVacant
        dates.push(date);
    }
    return dates;
}


export function generateTimeSlots(isVacant = false, activeDate, is24window = false) {
    const slots = [];
    const startTime = 10; // 10 AM
    const endTime = 18.5; // 6:30 PM

    const now = new Date();
    const nowInBoston = new Date(now.toLocaleString('en-US', { timeZone: 'America/New_York' }));
    const currentHour = nowInBoston.getHours();
    const currentMinute = nowInBoston.getMinutes();
    const nowPlusxHoursInBoston = is24window ? new Date(nowInBoston.getTime() + 24 * 60 * 60 * 1000) : new Date(nowInBoston.getTime() + 48 * 60 * 60 * 1000); // Current time + 48 hours


    // const nowPlusxHours = is24window ? new Date(now.getTime() + 24 * 60 * 60 * 1000):new Date(now.getTime() + 48 * 60 * 60 * 1000); // Current time + 48 hours

    // Check if the activeDate is today
    const isToday = activeDate && new Date(activeDate).toDateString() === nowInBoston.toDateString();

    const activeDateObject = new Date(activeDate);


    for (let hours = startTime; hours <= endTime; ) {
        let hourPart = Math.floor(hours);
        let minutePart = (hours - hourPart) * 60;

        let period = hourPart >= 12 ? 'PM' : 'AM';
        let displayHour = hourPart % 12 === 0 ? 12 : hourPart % 12;
        let displayMinute = minutePart === 0 ? '00' : minutePart;

        let time = `${displayHour}:${displayMinute} ${period}`;
        let slotDate  = new Date(activeDateObject)
        slotDate.setHours(hourPart, minutePart, 0, 0); // set hours for activeDate

        if (!isVacant) {
            // If not vacant, include all slots
            if (slotDate > nowPlusxHoursInBoston)  {
                slots.push(time)
            }
        } else {
            if (isToday) {
                // If vacant and today, include only future slots
                if (hourPart > currentHour || (hourPart === currentHour && minutePart > currentMinute)) {
                    slots.push(time);
                }
            } else {
                // If vacant but not today, include only the time slots 48 hours in the future from now
                slots.push(time);
            }
        }

        hours += 0.25; // increment by 15 minutes
    }

    return slots;
}

export function checkDateRequirement(obj, isVacant) {
    if (isVacant) {
        // Vacant: At least 2 dates with at least 1 time slot each
        return Object.keys(obj).length >= 1 && Object.entries(obj).every(([key, value]) => 
            Array.isArray(value) && value.length >= 1
        );
    } else if( new URL(window.origin).hostname.split('.')[0] === "starrealty") {
        // Non-vacant: At least 1 dates with at least 2 time slots each
        return Object.keys(obj).length >= 1 && Object.entries(obj).every(([key, value]) => 
        Array.isArray(value) && value.length >= 2
     );
    }
    else {
        // Non-vacant: At least 2 dates with at least 2 time slots each
        return Object.keys(obj).length >= 2 && Object.entries(obj).every(([key, value]) => 
            Array.isArray(value) && value.length >= 2
        );
    }
}

export const colorArray = [
    "#346751", // Emerald Green
    "#F1C40F", // Vivid Yellow
    "#3498DB", // Bright Blue
    "#E74C3C", // Strong Coral
    "#9B59B6", // Amethyst Purple
    "#16A085", // Sea Green
    "#2980B9", // Lively Ocean Blue
    "#E67E22", // Carrot Orange
    "#2ECC71", // Neon Green
    "#D35400"  // Pumpkin Orange
];

export function removeSlashes(inputString) {
    return inputString.replace(/\//g, '');
}
  
export const toProperCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};
  
export const toPercentage = (num) => {
    return num * 100; 
}

export function formatCurrency(number) {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
    }).format(Number(number));
}

// const formatCurrency = (value) => {
//     // Convert the input to a number and format it as currency
//     const numberValue = Number(value);
//     return numberValue.toLocaleString('en-US', {
//         style: 'currency',
//         currency: 'USD',
//     });
// };

export function formatNumber(num) {
    return String(parseFloat(num));
}

export function convertDates(showingTrendData) {
    // Loop over each key in the showingTrendData object ('weekly' and 'monthly')
    Object.keys(showingTrendData).forEach(timeFrame => {
        // Loop over each category (like 'Requested', 'Cancelled', 'Completed')
        showingTrendData[timeFrame].forEach((categoryData, index) => {
            // Assign a color from the colorArray based on the index
            // Ensure the index wraps around if there are more categories than colors
            categoryData.color = colorArray[index % colorArray.length];
            
            // Loop over each value entry
            categoryData.values.forEach(dataEntry => {
                // Convert the date string to a Date object
                dataEntry.date = new Date(dataEntry.date);
            });
        });
    });
    return showingTrendData;
}

export function compareDateTime(dateTime) {
    const currentDate = new Date();
    const inputDate = new Date(dateTime);
  
    // Calculate the time difference in milliseconds
    const timeDifference = currentDate - inputDate;
  
    // Calculate time units
    const minutes = Math.floor(timeDifference / (1000 * 60));
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30)); // Approximation for a month
  
    if (minutes < 60) {
      return minutes + 'min(s) ago';
    } else if (hours < 24) {
      return hours + 'hr(s) ago';
    } else  {
      return days + 'd ago';
    // } else {
    //   return months + ' month(s) ago';
    // }
  }
}

export const debounce = (func, wait)  => {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

export function throttle(func, limit) {
    let inThrottle;
    return function() {
        const args = arguments;
        const context = this;
        if (!inThrottle) {
            func.apply(context, args);
            inThrottle = true;
            setTimeout(() => (inThrottle = false), limit);
        }
    };
}

export function throttleAndDebounce(func, throttleMs, debounceMs) {
    let lastFunc, lastRan;
    return function() {
        const context = this;
        const args = arguments;
        if (!lastRan) {
            func.apply(context, args);
            lastRan = Date.now();
        } else {
            clearTimeout(lastFunc);
            lastFunc = setTimeout(function() {
                if ((Date.now() - lastRan) >= throttleMs) {
                    func.apply(context, args);
                    lastRan = Date.now();
                }
            }, Math.max(throttleMs - (Date.now() - lastRan), debounceMs));
        }
    };
}


export const allDocs = [
    "passport",
    "photo id",
    "i20",
    "visa",
    "proof of funds",
    "admit letter",
    "credit report",
    "pay stub(1)",
    "pay stub(2)",
    "offer letter"
]

export const baseDocs = {
    "International Student": new Set(["passport", "i20", "visa", "proof of funds", "admit letter"]),
    "Student": new Set(["photo id", "admit letter"]),
    "Working Professional": new Set(["photo id", "credit report", "pay stub(1)", "pay stub(2)"]),
    "Family": new Set([])
};

export const dateDiff = (dateBegin, dateEnd) => {
    const date1 = new Date(dateBegin);
    const date2 = new Date(dateEnd);

    // Initialize difference variables
    let months = 0;
    let days = 0;

    // Calculate difference in months
    months = (date2.getFullYear() - date1.getFullYear()) * 12;
    months -= date1.getMonth();
    months += date2.getMonth();

    // Calculate days difference by checking if the day of the month in date2 is less than date1
    if (date2.getDate() < date1.getDate()) {
        months--;
        const newDate2 = new Date(date2.getFullYear(), date2.getMonth(), 0); // Last day of the previous month of date2
        days = newDate2.getDate() - date1.getDate() + date2.getDate();
    } else {
        days = date2.getDate() - date1.getDate();
    }

    return { months, days };

}

export function camelCaseToTitle(camelCase) {
    // Split the string at each point where an uppercase letter follows a lowercase letter
    const words = camelCase.split(/(?<=[a-z])(?=[A-Z])/);

    // Capitalize the first letter of each word and join them back with spaces
    const title = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

    return title;
}

// const title = camelCaseToTitle("cityStateZip");
// console.log(title); // "City State Zip"

const sortDateValues = (values) => {
    return values.sort((a, b) => new Date(a.date) - new Date(b.date));
};

export const sortTrendData = (trendData) => {
    return trendData.map(category => ({
        ...category,
        values: sortDateValues(category.values)
    }));
};

export const formatAddress = (address) => {
    return address
        .toLowerCase()            // Convert to lowercase
        .replace(/\s+/g, '-')     // Replace spaces with hyphens
        .replace(/[.#]/g, '');    // Remove '.' and '#'
}