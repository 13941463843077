import React, { useState, useRef, useEffect, useCallback, useMemo, useContext } from 'react';
import IframeAutoHeight from './iframe-height';
import { throttle } from '../../../../utilities/utility';
import { ApiContext } from '../../../../context/apiContext';
import { toast } from 'react-toastify'
import ImageModal from '../../../image-modals/image-modals';
// import { PDFDocument } from 'pdf-lib'
import { toProperCase } from '../../../../utilities/utility';
import {
    Container,
    Sidebar,
    SidebarSection,
    SidebarSubsection,
    Content,
    DocumentSection,
    AddDocs,
    NoDocDiv,
    NoDocText,
    ButtonDiv,
    RoundDiv
  } from './package.styles'

import { ReactComponent as Document } from '../../../../assets/document-upload.svg'
import Loader from '../../../loader/loader';
// import { ReactComponent as Time } from '../../../../assets/Time Circle.svg'

const  Package =({ applicants, applicationPackage, setActiveTab, applicationId, isLoading = false}) => {
    const [activeSection, setActiveSection] = useState(null);
    const [expandedSection, setExpandedSection] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const contentRefs = useRef({});
    const contentRef = useRef(null);
    const api = useContext(ApiContext);

    // console.log(applicants)

    const docPriority = {
        "photo Id":1,
        "passport": 2,
        "i20": 3,
        "visa": 4,
        "loan sanction letter": 5,
        "guarantor form": 6,
        "pay stubs(1)": 7,
        "pay stubs(2)": 8,
        "offer letter": 9,
        "admit letter": 10,
        // Add other document types and their order here
    };

    const sectionOrder = {
        "Application Package": 1,
        "Application Forms": 2,
        "Guarantor": 3,
        "Supporting Documents": 4,
    };

    const sortDocuments = (a, b) => {
        return (docPriority[a.documentType.toLowerCase()] || Infinity) - (docPriority[b.documentType.toLowerCase()] || Infinity);
    };

    const sortSections = (a, b) => {
        return (sectionOrder[a.section] || Infinity) - (sectionOrder[b.section] || Infinity);
    };

    const getSections = useCallback((applicants, applicationPackage) => {
        let sections = [];

        // Check if applicationPackage exists and add it as a new section
        if (applicationPackage?.value) {
            sections.push({
                key: 'application-package',
                section: 'Application Package',
                subSection: 'Application Package',
                document: window.location.origin + '/media/' + applicationPackage.value,
                docName: 'Application Package',
                docHeight: applicationPackage.height // Set a default height or customize as needed
            });
        }

        applicants.forEach(app => {
        // Application forms
            const applicationDocs = app.documents.filter(doc => doc.documentType === 'Application Form');
            sections = sections.concat(applicationDocs.map(doc => ({
                key: `application-${doc.id}`,
                id: doc.id,
                section: 'Application Forms',
                subSection: `${app.name} - Application Form`,
                document:  doc.value? window.location.origin + '/media/' + doc.value: null,
                email: app.email,
                docName: doc.documentType,
                docHeight: doc.height - 150
            })));
      
            // Guarantor forms
            if(app.hasCosigner){
                const guarantorDocs = app.cosigner.documents.sort(sortDocuments);
                sections = sections.concat(guarantorDocs.map(doc => ({
                    key: `guarantor-${doc.id}`,
                    id: doc.id,
                    section: 'Guarantor',
                    subSection: `${app.cosigner.name} - ${doc.documentType}`,
                    sortHint: docPriority[doc.documentType.toLowerCase()] || Infinity,
                    document:  doc.value? window.location.origin + '/media/' + doc.value: null,
                    email: app.email,
                    docName: doc.documentType,
                    docHeight: doc.height - 150
                })));
            }
      
            // Supporting documents
            const supportingDocs = app.documents.filter(doc => doc.documentType !== 'Application Form').sort(sortDocuments);
            // console.log('supporting docs', supportingDocs)
            sections = sections.concat(supportingDocs.map(doc => ({
                key: `supporting-docs-${doc.id}`,
                id: doc.id,
                section: 'Supporting Documents',
                subSection: `${app.name} - ${doc.documentType}`,
                sortHint: docPriority[doc.documentType.toLowerCase()] || Infinity,
                document: doc.value? window.location.origin + '/media/' + doc.value: null,
                email: app.email,
                docName: doc.documentType,
                docHeight: doc.height - 150
            })));
        });

        // // Sort sections by predefined order and then by name within each section
        sections.sort((a, b) => {
            const sectionComparison = sortSections(a, b);
            if(sectionComparison !== 0) return sectionComparison;
            return sections
            // const nameComparison = a.subSection.localeCompare(b.subSection);
            // if (nameComparison !== 0) return nameComparison;
            // // When names are the same, sort by sortHint
            // return a.sortHint - b.sortHint;
        });
        return sections;
    },[]);

    const groupSectionsByTitle = useCallback((sections) => {
        const groupedSections = sections.reduce((acc, curr) => {
            // Check if the section already exists
            if (!acc[curr.section]) {
                acc[curr.section] = {
                title: curr.section,
                subsections: [],
                };
            }
            // Add the current subsection to the appropriate section
            acc[curr.section].subsections.push(curr);
            return acc;
        }, {});
        return Object.values(groupedSections); // Convert the grouped sections object back into an array
    },[]);

    const handleSectionClick = useCallback((sectionTitle) => {
        const firstSubKey = groupedSections.find(sec => sec.title === sectionTitle)?.subsections[0]?.key;
        let sectionElement
        if(firstSubKey){
            sectionElement = contentRefs.current[firstSubKey];
        }else {
            sectionElement = contentRefs.current[sectionTitle];
        }
        if (sectionElement && contentRef.current) {
          const topPos = sectionElement.offsetTop - contentRef.current.offsetTop;
          contentRef.current.scrollTo({ top: topPos, behavior: 'smooth' });
        }
    }, []);

    useEffect(() => {
        const handleScroll = throttle(() => {
            let newActiveSection = null;
            const scrollPosition = contentRef.current.scrollTop + (contentRef.current.clientHeight / 2);
    
            Object.entries(contentRefs.current).forEach(([key, ref]) => {
                if (ref) {
                    // Use getBoundingClientRect for relative positioning; adjust if needed
                    const rect = ref.getBoundingClientRect();
                    const relativeTop = rect.top + contentRef.current.scrollTop - contentRef.current.offsetTop;
                    if (relativeTop < scrollPosition && (relativeTop + rect.height) > scrollPosition) {
                        newActiveSection = key;
                    }
                }
            });
    

            if (newActiveSection !== null) {
                setActiveSection(newActiveSection);
                // Determine the parent section of the active subsection to auto-expand it
                const parentSection = sections.find(sec => sec.key === newActiveSection)?.section;
                if (parentSection && expandedSection !== parentSection) {
                    setExpandedSection(parentSection);
                }
            }
        },20);
    
        const contentElement = contentRef.current;
        if (contentElement) {
            contentElement.addEventListener('scroll', handleScroll);
        }
    
        return () => {
            if (contentElement) {
                contentElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);
    
    const handleReminder = async (sub) => {
        // const log = console.log;
        const reminderData = {
            email: sub.email,
            documentName: sub.docName,
            documentId: sub.id,
            applicationId: applicationId
        };
      
        try {
            const response = await api.put('/api/application/document/reminder', reminderData);
            if (!response.success) {
                throw new Error(`HTTP error! Status: ${response.message}`);
            }
            
            toast.success('Reminder sent successfully');

        } catch (error) {
            toast.error(error.message);
        }
    };

    const openModal = (content) => {
        setModalContent(content);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent(null);
    };


    const sections = useMemo(() => getSections(applicants, applicationPackage), [applicants, applicationPackage]);
    const groupedSections = useMemo(() => groupSectionsByTitle(sections), [sections]);

    // console.log(groupedSections)

    return (
        <Container>
            {
                isLoading && <Loader />
            }
            <Sidebar>
            {
                groupedSections.map((section) => (
                    <React.Fragment key={section.title}>
                        <SidebarSection 
                            active = {section.subsections.some(sub => sub.key === activeSection)}
                            onClick={() => handleSectionClick(section.title)}
                        >
                            {section.title}
                        </SidebarSection>
                        {
                            expandedSection === section.title && section.subsections.map((sub) => (
                                <SidebarSubsection
                                    key={sub.key}
                                    onClick={() => handleSectionClick(sub.key)}
                                    active={activeSection === sub.key}
                                >
                                    {toProperCase(sub.subSection)}
                                </SidebarSubsection>
                            ))
                        }
                    </React.Fragment>
                ))
            }
            <AddDocs onClick={()=>setActiveTab('Additional Docs')}><span>Request Other Documents</span><span><strong>&#43;</strong></span></AddDocs>
            </Sidebar>
            <Content ref={contentRef}>
                {
                    groupedSections.map((section) => (
                        <React.Fragment key={section.title}>
                            {/* <h2 ref={(el) => contentRefs.current[section.title] = el}>{section.title}</h2> */}
                            {
                                // TODO: FIX PDF VIEWE
                                section.subsections.map((sub) => (
                                    <DocumentSection
                                        key={sub.key}
                                        ref={(el) => contentRefs.current[sub.key] = el}
                                        onClick={() => openModal(
                                            <IframeAutoHeight src={sub.document} height={sub.docHeight} />
                                        )}
                                    >
                                        {
                                            sub.document ? 
                                            <IframeAutoHeight 
                                                src={sub.document} 
                                                height = {sub.docHeight}
                                            />
                                            :
                                            <NoDocDiv>
                                                <RoundDiv><Document/></RoundDiv>
                                                <NoDocText>Document not uploaded yet</NoDocText>
                                                <ButtonDiv onClick={() => handleReminder(sub)}>Remind</ButtonDiv>
                                            </NoDocDiv>
                                        }
                                    </DocumentSection>
                                ))
                            }
                        </React.Fragment>
                    ))
                }
            </Content>
            {isModalOpen && (
                <ImageModal onClose={closeModal}>
                    {modalContent}
                </ImageModal>
            )}
        </Container>
    );
}

export default Package