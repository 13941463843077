import styled from "styled-components";
import { FaCircle } from 'react-icons/fa'; // This is a filled circle that can act as a bullet

export const Canvas = styled.div`
    padding: 20px;
    gap: 30px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: -webkit-fill-available;
`;

export const IconButton = styled.button`
    background: none;
    border: none;
    aspect-ratio : 1 / 1;
    cursor: pointer;
    background: ${props => props.selected? '#F0F8FF': props.reminder? '#064274':'#f7f7f7'};
    border: ${props => props.selected? '1px solid #064274':"1px solid #f7f7f7"};
    // padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;   
    
    .svg {
        height: 25px;
        width: 25px;
    }
`;

export const IconDiv = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;


export const Th = styled.th`
    color: var(--Light-Grey, #A4A4A4);
    leading-trim: both;
    text-edge: cap;

    /* Body/Medium */
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
`;

export const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

export const TableRow = styled.tr`
    &:not(:last-child) {
        td {
            border-bottom: 1px solid #f1f1f1; 
        }
        
    }
`;

export const TableCellWrapper = styled.div`
    padding: 5px 0; /* This replaces the vertical cell padding and adds space between rows */
`;

export const ApplicantName = styled.td`
    color: var(--Greyish-Black, #323232);

    /* Body/Large */
    font-family: Avenir;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;


export const BulletIcon = styled(FaCircle)`
    font-size: 8px; // Size it to look like a bullet
    vertical-align: middle; // Align with the text
    margin-right: 4px; // Space after the bullet
`;

const color = ({status}) => {
    if(status === "Pending"){
        return `    
            background-color: #FFD1D1; // soft red
            color: #D8000C; // darker red
        `;
    } else if(status === "Denied" ){
        return `    
            background-color: #FFF4E5; // soft amber
            color: #FFA500; // amber
        `;
    }
    else if (status === 'Accepted'){
        return `
            background-color: #E3FFE6;
            color: #1CCB00;
        `; 
    }
}

export const StatusBadge = styled.div`
    ${color}
    border-radius: 15px; // Fully rounded corners
    padding: 5px 10px; // Vertical and horizontal padding
    font-size: 12px; // Font size
    font-weight: 500; // Medium font weight
    user-select: none; // Prevent text selection
    display: inline-flex; // Use inline-flex to center content
    align-items: center; // Vertical align to center
    width: fit-content;
    justify-content: center; // Horizontal align to center
    text-edge: cap;
    min-width: 100px;
`;

export const ContactDiv = styled.button`
    display: flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    background: var(--F7, #F7F7F7);
    border: none;
    cursor: pointer;
    color: var(--Light-Grey, #A4A4A4);
    leading-trim: both;
    text-edge: cap;
    /* Body/Medium */
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
`;

export const PaymentAmount = styled.div`
    color: var(--Greyish-Black, #323232);
    leading-trim: both;
    text-edge: cap;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const AddButton = styled.div`
    cursor: pointer;
    border-radius: 56px;
    background: var(--Light-Blue, #F0F8FF);
    display: flex;
    padding: 4px 18px;
    justify-content: center;
    align-items: center;
    gap: 3px;
    color: var(--Blue-100, #064274);
    text-align: right;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    /* line-height: 20px; */
`;

export const FlexDiv = styled.div`
    display: grid;
    grid-template-columns: 1fr 1.4fr 1fr; /* Match the table's column structure */
    align-items: center;
    gap: 10px; /* Add space between columns */
    width: 100%; /* Ensure it spans the full width */
    padding: 10px 0;
`;

export const FlexDivColumn = styled.div`
    flex-direction: column;
    display: flex;
    // gap: 15px;
    width: 100%; /* Ensure full width for alignment */
`;

export const Heading = styled.div`
    color: var(--Greyish-Black, #323232);
    leading-trim: both;
    text-edge: cap;

    /* Title/Extra Small */
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const ApplicantDetails = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; /* Same column structure as the first table */
    align-items: center;
    padding: 5px 0; /* Consistent padding */
    width: 100%; /* Ensure it stretches the full width */

    &:not(:last-child) {
        border-bottom: 1px solid #f1f1f1; 
    }
`;

export const SubscriptText = styled.div`
    color: var(--Light-Grey, #A4A4A4);
    leading-trim: both;
    text-edge: cap;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;


export const ApplicationDiv = styled.div`
    display: flex;
    padding: 4px 12px;
    width: fit-content;
    align-items: center;
    gap: 5px;
    border-radius: 28px;
    border: 1px solid var(--Blue-100, #064274);
    background: var(--Light-Blue, #F0F8FF);
    color: #064274;
    justify-content: space-between;

    leading-trim: both;
    text-edge: cap;
    text-overflow: ellipsis;
    height: fit-content;
    cursor: pointer;

    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const ApplicationColor = ({status}) => {
    if(status === 'New'){
        return `
            background-color: #B2EBF2; // light turquoise
            color: #00796B; // rich teal
        `
    }else if (status === 'Incomplete'){
        return `
            background-color: #EDE7F6; // soft lavender
            color: #6A1B9A; // darker purple
        `
    }else if (status === 'Approved'){
        return `
            background-color: #E3FFE6;
            color: #1CCB00;
        ` 
    }else if (status === 'Under Review'){
        return `
            background-color: #FFF4E5; // soft amber
            color: #FFA500; // amber
        `;
    }else if (status === 'Rejected'){
        return `
            background-color: #FFD1D1; // soft red
            color: #D8000C; // darker red
        `;
    }else if (status === 'Pending'){
        return `
            background-color: #fff49b; // soft lavender
            color: #DAA520; // darker purple
        `
    }
}

export const ApplicationStatusBadge = styled.span`
    ${ApplicationColor}
    border-radius: 15px; // Fully rounded corners
    padding: 5px 10px; // Vertical and horizontal padding
    font-size: 12px; // Font size
    font-weight: 600; // Medium font weight
    // cursor: pointer; // Cursor changes to pointer to indicate it's clickable
    user-select: none; // Prevent text selection
    display: inline-flex; // Use inline-flex to center content
    align-items: center; // Vertical align to center
    justify-content: center; // Horizontal align to center
    width: fit-content;
    min-width: 100px;
`;