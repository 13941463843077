// Modal.js
import ReactDOM from 'react-dom';
import React, { useState, useEffect } from 'react';
import FormInput from '../form-input/form-input'
import { ModalBackground, ModalContent, WrapDiv, ButtonDiv, Heading, NextButton, PreviousButton } from './new-payment-modality.styles';


const NewPayment = ({ isOpen, onClose, setNewPayment, handleAddPayment, newPayment }) => {
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if(!isOpen) handleClose()
    }, [isOpen]); 

    const validateInputs = () => {
        let newErrors = {};
        if(!newPayment.type) newErrors.type = "Payment Type is required";
        if(!newPayment.amount) newErrors.amount = "Payment Amount is required";
        if(!newPayment.dueDate) newErrors.date = "Payment Date is required";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const handleSubmitPayment = () => {
        if(validateInputs()){
            handleAddPayment();
        }
    }

    const handleClose = () => {
        setNewPayment({
            type: '', amount: 0, dueDate: '' 
        })
        setErrors({}); // This clears the errors when the component unmounts
        onClose();
    }

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <ModalBackground>
            <ModalContent>
                <WrapDiv>
                    <Heading>Add Payment</Heading>
                    <FormInput
                        type="text"
                        placeholder="Payment Type"
                        label = "Payment Type"
                        value={newPayment.type}
                        handleChange={value => setNewPayment(prev => ({ ...prev, type: value }))}
                        isRequired={true}
                        error = {errors.type}
                        // flexBasis={true}
                    />
                    <FormInput
                        type="number"
                        placeholder="Payment Amount"
                        label = "Payment Amount"
                        value={newPayment.amount}
                        handleChange={value => setNewPayment(prev => ({ ...prev, amount: value }))}
                        isRequired={true}
                        error = {errors.amount}
                        // flexBasis={true}
                    />
                    <FormInput
                        type="date"
                        label = "Payment Due"
                        value={newPayment.dueDate}
                        handleChange={value => setNewPayment(prev => ({ ...prev, dueDate: value }))}
                        isRequired={true}
                        error = {errors.date}
                        // flexBasis={true}
                    />
                </WrapDiv>
                <ButtonDiv>
                    <PreviousButton style ={{width:'150px'}} onClick={onClose}>Close</PreviousButton>
                    <NextButton style ={{width:'150px'}}  onClick={handleSubmitPayment}>Add Payment</NextButton>
                </ButtonDiv>
            </ModalContent>
        </ModalBackground>,
        document.getElementById('modal-root')
    );
};

export default NewPayment;
