import React, { useState, useContext } from "react";
import ReactDOM from 'react-dom';
import FormInput from "../form-input/form-input";
import NewPayment from "../new-payment-modality/new-payment-modality";
import moment from 'moment'
import { ApiContext } from "../../context/apiContext";
import { toast } from 'react-toastify'
import { formatCurrency } from "../../utilities/utility";

import { Section, Heading, AddButton, Table, TableCell, TableHeader, TableRow, PaymentDiv, NoPaymentDiv,
    ButtonDiv, NextButton, PreviousButton, ContentDiv, ModalOverlay, ModalWrapper, Title, SpaceDiv, FlexDiv, CloseButton
} from "./renewal-approval.styles";

const RenewalApproval = ({modalRenewal, offerPrice, onClose, updateRenewal, setIsLeaseModalOpen}) => {
    const api = useContext(ApiContext);
    const [errors, setErrors] = useState({});
    const [leaseStart, setLeaseStart] = useState()
    const [leaseEnd, setLeaseEnd] = useState()
    const [updatedRent, setUpdatedRent] = useState(offerPrice)

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deposits, setDeposits] = useState({})
    const [newPayment, setNewPayment] = useState({ type: '', amount: 0, dueDate: '' });

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const handleAddPayment = () => {
        setDeposits(currentDeposits => ({
            ...currentDeposits,
            [newPayment.type]: {
                amount: Number(newPayment.amount),
                dueDate: newPayment.dueDate
            }
        }));

        // Reset the form and close the modal
        setNewPayment({ type: '', amount: 0, dueDate: '' });
        handleCloseModal();
    };

    const handleRemovePayment = (type) => {
        setDeposits((currentDeposits) => {
            const updatedDeposits = { ...currentDeposits };
            delete updatedDeposits[type]; // Remove the deposit by type
            return updatedDeposits;
        });
    };

    const validateInputs = () => {
        let newErrors = {};

        if(!leaseStart) newErrors.leaseStart = "Lease Start is required!"
        if(!leaseEnd) newErrors.leaseEnd = "Lease Start is required!"
        if(!updatedRent) newErrors.updateRent = "Updated Rent is required!"

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if no errors
    }

    const handleApprove = async () => {
        if (!validateInputs()) return;
        const payload = {
            renewalId: modalRenewal.renewalId,
            applicationStatus: "Approved",
            leaseStartDate: leaseStart,
            leaseEndDate: leaseEnd,
            updatedRent: updatedRent,
            payments: deposits
        }

        try{
            const data = await api.put('/api/renewals/applicationUpdate', payload);
            updateRenewal(payload);
            if(modalRenewal.totalApplicants > 0){
                setIsLeaseModalOpen(true);
            }
        }catch(error){
            toast.error(`Could not approve application, try again in sometime!, ${error}`)
        }finally{
            onClose();
        }
    }

    const sendForms = async () => {
        if (!validateInputs()) return;
        const payload = {
            renewalId: modalRenewal.renewalId,
            leaseStartDate: leaseStart,
            leaseEndDate: leaseEnd,
            updatedRent: updatedRent,
            payments: deposits
        }

        try {
            const data = await api.put('/api/renewals/renewalForms/send', payload);
            updateRenewal(payload);
        }catch(error){
            toast.error(`Could not approve application, try again in sometime!, ${error}`)
        }finally{
            onClose();
        }
    }

    return ReactDOM.createPortal(
        <ModalOverlay onClick={onClose}>
            <ModalWrapper
                onClick={(e) => e.stopPropagation()}
            >
                {/* <Container> */}
                    <SpaceDiv>
                        <Title>{modalRenewal.totalApplicants > 0 ? "Renewal Terms":"Renewal Forms"}</Title>
                        <FlexDiv>
                            <span style={{fontSize:"14px"}}>Close</span>
                            <CloseButton onClick={onClose}>X</CloseButton>
                        </FlexDiv>
                    </SpaceDiv>
                    <ContentDiv>
                        <Section>
                            <FormInput
                                label="New Lease Start"
                                type="date"
                                isRequired={true}
                                flexBasis={false}
                                defaultValue={leaseStart}
                                handleChange={(value) => setLeaseStart(value)}
                                error = {errors.leaseStart}
                            />
                            <FormInput
                                label="New Lease End"
                                type="date"
                                isRequired={true}
                                flexBasis={false}
                                defaultValue={leaseEnd}
                                handleChange={(value) => setLeaseEnd(value)}
                                error = {errors.leaseEnd}
                            />
                        </Section>
                        <Section>
                            <FormInput
                                label="Updated Rent"
                                type="number"
                                isRequired={true}
                                flexBasis={true}
                                defaultValue={updatedRent}
                                handleChange={(value) => setUpdatedRent(value)}
                                error = {errors.updateRent}
                            />
                        </Section>
                        <PaymentDiv>
                            <Section>
                                <Heading>Payments (if any)</Heading>
                                <AddButton onClick={handleOpenModal}>Add +</AddButton>
                            </Section>
                            {
                                Object.entries(deposits).length > 0 ?
                                    <Table>
                                        <TableHeader>
                                            <TableRow>
                                                <TableCell header>Deposit Type</TableCell>
                                                <TableCell header align="center">
                                                    Amount
                                                </TableCell>
                                                <TableCell header align="center">
                                                    Due Date
                                                </TableCell>
                                                <TableCell header align="center">Actions</TableCell>
                                            </TableRow>
                                            
                                        </TableHeader>
                                        <tbody>
                                            {
                                                Object.entries(deposits).map(([type, details], index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{type}</TableCell>
                                                        <TableCell align="center">{formatCurrency(details.amount)}</TableCell>
                                                        <TableCell align="center">{moment(details.dueDate).format("DD-MM-YYYY")}</TableCell>
                                                        <TableCell align="center">
                                                        <button onClick={() => handleRemovePayment(type)} style={{ background: "none", border: "none", cursor: "pointer" }}>
                                                            ❌
                                                        </button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                    :
                                    <NoPaymentDiv>No Payments Added</NoPaymentDiv>
                            }
                        </PaymentDiv>
                    </ContentDiv>
                    <ButtonDiv>
                        <PreviousButton onClick={onClose}>Back</PreviousButton>
                        {
                            modalRenewal.totalApplicants > 0 ?
                            <NextButton onClick={() => handleApprove()}>Continue to Lease</NextButton>
                            :
                            <NextButton onClick={() => sendForms()}>Send Renewal Forms</NextButton>
                        }
                        
                    </ButtonDiv>
                    <NewPayment 
                        isOpen={isModalOpen} 
                        onClose={handleCloseModal}
                        setNewPayment={setNewPayment}
                        handleAddPayment = {handleAddPayment}
                        newPayment={newPayment}
                    /> 
                {/* </Container> */}
            </ModalWrapper>
        </ModalOverlay>
        ,
        document.getElementById("modal-root")
    )
}

export default RenewalApproval