import styled from "styled-components";

export const Canvas = styled.div`
    padding: 20px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: -webkit-fill-available;
`;

export const FlexDiv = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;
    width: 100%; /* Ensure full width for alignment */
`;

export const FlexDivColumn = styled.div`
    flex-direction: column;
    display: flex;
    width: 100%; /* Ensure full width for alignment */
    gap: 5px;
`;

export const Heading = styled.div`
    color: var(--Greyish-Black, #323232);
    leading-trim: both;
    text-edge: cap;
    /* Title/Extra Small */
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const ActionButton = styled.button`
    // background-color: #064274    ;
    color: white;
    border: none;
    border-radius: 4px;
    // padding: 12px;
    cursor: pointer;
    font-size: 14px;
    height: 30px;

    // &:hover{ 
    //     background-color: #002456;
    //     transition: background-color 0.2s;
    // }

    background-color: #E45A5C;
    color: #fff;
    
    &:hover{ 
        background-color: #B5373A; 
        transition: background-color 0.2s;
    }

`;

// Styled Components
export const Card = styled.div`
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 10px;
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.2s ease-in-out;

    &:hover {
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
    }

    display: flex;
    flex-direction: column;
    // gap: 10px;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
    padding: 8px;
`;

export const Title = styled.div`
    font-size: 16px;
    font-weight: 600;
    color: #333333;
`;

export const Date = styled.span`
    font-size: 14px;
    font-weight: 400;
    color: #888888;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 10px;
`;

export const Description = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: #555555;
`;

export const Rent = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: #333333;

    strong {
        font-weight: 600;
    }
`;

export const Validity = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: #555555;

    strong {
        font-weight: 600;
    }
`;

export const Attachment = styled.a`
    font-size: 14px;
    font-weight: 500;
    color: #E45A5C;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;