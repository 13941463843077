import styled from "styled-components"

export const PropertyPanelContainer = styled.div`
    min-width: 350px;
    width: 50%;
    background: ${({ background }) => background};
    padding: 50px;
    border-right: ${({ background }) => background === '#FFFFFF'? '3px solid #EEEEEE;':'1px solid #EEEEEE' };
    display: flex;
    gap: 15px;
    flex-direction: column;

    @media screen and (max-width: 1000px) {
        padding: 20px;
    }

    div {
        color: ${({ background }) => background === '#FFFFFF' && '#525252'}
    }

    @media screen and (max-width: 800px) {
        // max-width: 100%;
        min-width: unset;
        padding: 20px;
        width: unset;
        border-right: unset;
        border-bottom: ${({ background }) => background === '#FFFFFF' && '2px solid #EEEEEE;' };
    }
`;

export const LogoStyled = styled.div`
    margin-bottom: 20px;
    width: 200px;

    @media screen and (max-width: 800px) {
        margin-bottom: unset;
        width: 150px;
        display: ${props => props.location === 'application-status' && 'none'};
    }
`;

export const PropertyImage = styled.div`
    background-image: ${({ imageUrl }) => `url(${imageUrl})`};
    width: 100%;
    height: 300px;
    background-size: cover;
    box-shadow: 0px 0px 10px #ffffff40;
    border-radius: 7px;

    @media screen and (max-width: 800px) {
        height: 200px;
        width: 55%;
    }
`;



export const PropertyTitle = styled.div`
    overflow: hidden;
    color: #ffffff;
    text-overflow: ellipsis;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 33px */


    @media screen and (max-width: 800px){
        display: none;
    }
`;

export const PropertyTitleMob = styled(PropertyTitle)`
    display: none;
    @media screen and (max-width: 800px){
        display: unset;
        font-size: 20px;
    }
`;

export const DetailsRow = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    @media screen and (max-width: 800px){
        flex-direction: column;
        gap: 20px;
        // width: 30%;
        align-items: normal;
    }
`;

export const Details2Row = styled(DetailsRow)`
    @media screen and (max-width: 800px){
        display: none;
    }
`;

export const DetailItem = styled.div`
    color: #ffffff;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 5px;
`;

export const FlexDiv = styled.div`
    all: inherit;
    min-width: unset;
    padding: unset;
    border: unset;
    width: 100%;

    @media screen and (max-width: 800px){
        all: unset;
        width: unset;
        display: flex;
        gap: 15px;
        align-items: center;
        display: ${props => props.location === 'application-status' && 'none'};
    }

`;
