import React, { useState, useEffect } from "react";
import { baseDocs, allDocs, toProperCase } from "../../utilities/utility";
import FormInput from "../form-input/form-input";
import { toast } from "react-toastify";
import {
  DropdownDiv,
  Dropdown,
  Section,
  Heading,
  Label,
  SectionTitle,
  ApplicantContainer,
  ApplicantDetailGrid,
  RadioButtonLabel,
  WrapDiv,
  FlexDiv,
  GapDiv,
  NextButton,
  CloseButton,
  StyledInput,
  StyledButton,
  DocumentInputWrapper
} from "./base-applicant-form.styles";


const BaseApplicantForm = ({
  applicant,
  applicantDocuments,
  setApplicant,
  setApplicantDocuments,
  handleSave,
  mode,
  applicationPackageState,
  onClose = null
}) => {

  const [errors, setErrors] = useState({});
  const validateApplicants = () => {
    let newErrors = {};
  
    // Regex for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    // Validate applicant details
    if (!applicant.name) newErrors.name = "Name is required";
    if (!applicant.email) {
      newErrors.email = "Email is required";
    } else if (!emailRegex.test(applicant.email)) {
      newErrors.email = "Invalid email format";
    }
    if (!applicant.applicantType) newErrors.applicantType = "Type is required";
  
    // Validate cosigner details (if applicable)
    if (applicant.hasCosigner === true) {
      if (!applicant.cosigner.name) newErrors.cosignerName = "Name is required";
      if (!applicant.cosigner.email) {
        newErrors.cosignerEmail = "Email is required";
      } else if (!emailRegex.test(applicant.cosigner.email)) {
        newErrors.cosignerEmail = "Invalid email format";
      }
    }
  
    // Update errors state
    setErrors(newErrors);
  
    // Return true if there are no errors, false otherwise
    return Object.keys(newErrors).length === 0;
  };
  

  const handleApplicantTypeChange = (e) => {
    const newType = e.target.value;
    setApplicant((prev) => ({ ...prev, applicantType: newType }));

    // Directly use the Set from baseDocs for the new applicant type
    if (applicationPackageState) return;
    const requiredDocsForType = baseDocs[newType];
    setApplicantDocuments(new Set(requiredDocsForType)); // This is now correct as baseDocs values are Sets
  };

  const handleDocumentChange = (doc, isChecked) => {
    setApplicantDocuments((currentDocs) => {
      // Keep using Set for applicantDocuments
      const updatedDocs = new Set(currentDocs);

      if (isChecked) {
        updatedDocs.add(doc);
      } else {
        updatedDocs.delete(doc);
      }
      return new Set(updatedDocs);
    });
  };

  const [newOption, setNewOption] = useState("");

  const addRemoveGuarantor = (value) => {
    if (value) {
      setApplicant((prev) => ({
        ...prev,
        hasCosigner: true,
        cosigner: {
          ...prev.cosigner,
          requiredDocs: [
            "photo id",
            "credit report",
            "pay stub(1)",
            "pay stub(2)",
          ],
        },
      }));
    } else {
      setApplicant((prev) => ({
        ...prev,
        hasCosigner: false,
        cosigner: {
          name: "",
          email: "",
          requiredDocs: [],
        },
      }));
    }
  };

  const addNewDocument = () => {
    if (newOption !== "") {
      setApplicantDocuments((currentDocs) => {
        const updatedDocs = new Set(currentDocs);
        updatedDocs.add(newOption);
        return new Set(updatedDocs);
      });
      setNewOption("");
    }
  };

  const handleSubmit = () => {
    if (validateApplicants()) {
      handleSave();
      if(applicationPackageState){
        toast.info('Update your application package if necessary and save your changes to update application!')
      }else{
        toast.info('Save your changes to update application!')
      }
      
    }
  };

  return (
    <ApplicantContainer style = {{border: 'unset'}}>
      <Section style={{ gap: "10px" }}>
        <FlexDiv>
          <SectionTitle>Applicant Details</SectionTitle>
          <CloseButton onClick={onClose}>x</CloseButton>
        </FlexDiv>
        <WrapDiv>
          <FormInput
            label="Name"
            type="text"
            isRequired={true}
            defaultValue={applicant.name}
            handleChange={(value) =>
              setApplicant((prev) => ({ ...prev, name: value }))
            }
            flexBasis={true}
            error={errors.name}
          />
          <FormInput
            label="Email"
            type="text"
            isRequired={true}
            defaultValue={applicant.email}
            handleChange={(value) =>
              setApplicant((prev) => ({ ...prev, email: value }))
            }
            flexBasis={true}
            error={errors.email}
          />
          <DropdownDiv>
            <FlexDiv>
              <div>
                Applicant Type<span style={{ color: "red" }}>*</span>
              </div>
              {errors.applicantType && (
                <span style={{ color: "red" }}>{errors.applicantType}</span>
              )}
            </FlexDiv>
            <Dropdown
              onChange={(e) => handleApplicantTypeChange(e)}
              value={applicant.applicantType || null}
            >
              <option value={null} disabled selected>
                Select Applicant Type
              </option>
              <option value="International Student">International Student</option>
              <option value="Student">Student</option>
              <option value="Working Professional">Working Professional</option>
              <option value="Family">Family</option>
            </Dropdown>
          </DropdownDiv>
        </WrapDiv>
      </Section>
      {
        !applicationPackageState && 
        <>
          <Section style={{ gap: "10px" }}>
            <Heading>Select Documents Required</Heading>
            <ApplicantDetailGrid>
              {allDocs.map((doc, index) => (
                <RadioButtonLabel>
                  <input
                    type="checkbox"
                    checked={applicantDocuments.has(doc)}
                    onChange={(e) => handleDocumentChange(doc, e.target.checked)}
                  />
                  {toProperCase(doc)}
                </RadioButtonLabel>
              ))}
              {Array.from(applicantDocuments).map((doc, index) => {
                if (doc !== "Application Form" && !allDocs.includes(doc)) {
                  return (
                    <RadioButtonLabel key={`extra-${index}`}>
                      <input
                        type="checkbox"
                        checked={true}
                        onChange={(e) =>
                          handleDocumentChange(doc, e.target.checked)
                        }
                      />
                      {toProperCase(doc)}
                    </RadioButtonLabel>
                  );
                }
                return null;
              })}
            </ApplicantDetailGrid>
          </Section>
          <DocumentInputWrapper>
            <StyledInput
              type="text"
              value={newOption}
              onChange={(e) => setNewOption(e.target.value)}
              placeholder="Add new document"
            />
            <StyledButton onClick={addNewDocument}>+</StyledButton>
          </DocumentInputWrapper>
        </>
      }
      <Section style={{ gap: "10px" }}>
        <Heading>Does Applicant have a Guarantor?</Heading>
        <GapDiv>
          <Label
            style={{
              color: "#2C2C2C",
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <input
              type="radio"
              value="Yes"
              name="Guarantor"
              checked={applicant.hasCosigner === true}
              onChange={(e) => addRemoveGuarantor(true)}
            />
            Yes
          </Label>
          <Label
            style={{
              color: "#2C2C2C",
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <input
              type="radio"
              value="No"
              name="Guarantor"
              checked={applicant.hasCosigner === false}
              onChange={(e) => addRemoveGuarantor(false)}
            />
            No
          </Label>
        </GapDiv>
      </Section>
      {applicant?.hasCosigner && (
        <Section style={{ gap: "10px" }}>
          <SectionTitle>Guarantor Details</SectionTitle>
          <WrapDiv>
            <FormInput
              label="Cosigner Name"
              type="text"
              isRequired={true}
              defaultValue={applicant?.cosigner?.name}
              handleChange={(value) =>
                setApplicant((prev) => ({
                  ...prev,
                  cosigner: { ...prev.cosigner, name: value },
                }))
              }
              flexBasis={true}
              error={errors.cosignerName}
            />
            <FormInput
              label="Cosigner Email"
              type="text"
              isRequired={true}
              defaultValue={applicant?.cosigner?.email}
              handleChange={(value) =>
                setApplicant((prev) => ({
                  ...prev,
                  cosigner: { ...prev.cosigner, email: value },
                }))
              }
              flexBasis={true}
              error={errors.cosignerEmail}
            />
          </WrapDiv>
        </Section>
      )}
      <GapDiv>
        <NextButton onClick={handleSubmit}>
          {mode === "edit" ? "Update" : "Add"}
        </NextButton>
      </GapDiv>
    </ApplicantContainer>
  );
};

export default BaseApplicantForm;
