import styled from "styled-components";

export const FilterBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

export const SpaceDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
`;

export const NavigationButton = styled.button`
    cursor: pointer;
    background: #f7f7f7;
    border: none;
    width: 35px;
    height: 35px;
    padding: 8px;
    &:hover {
        background-color: #f5f5f5; // A subtle hover effect
    }
    border-radius: 10px;
`;

export const FlexDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const TextNav = styled.span`
    color: var(--Light-Grey, #A4A4A4);
    leading-trim: both;
    text-edge: cap;

    /* Label/Small */
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    span {
        color: var(--Greyish-Black, #323232);
    }
`;

export const SignButton = styled.button`
    background-color: #064274    ;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 12px;
    width: 100%;
    cursor: pointer;
    font-size: 14px;
    width: 200px;

    &:hover{ 
        background-color: #002456;
        transition: background-color 0.2s;
    }

`;

export const MoreActionsButton = styled(SignButton)`
    background-color: #E45A5C;
    &:hover{ 
        background-color: #B5373A; 
        transition: background-color 0.2s;
    }
    
`;

export const CancelButton = styled(SignButton)`
    background-color: #F7F7F7    ;
    color: #323232;

    &:hover{ 
        background-color: #dedede80;
        transition: background-color 0.2s;
    }
`;

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

export const ModalContent = styled.div`
    background: #fff;
    padding: 15px;
    border-radius: 8px;
    // box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
`;

export const ModalActions = styled.div`
    display: flex;
    justify-content: center;
    display: flex;
    gap: 10px;
`;
