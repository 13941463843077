import React, { useState, useEffect, useRef } from "react";
import {
    DropdownContainer,
    InputContainer,
    StyledInput,
    SearchIcon,
    CloseIcon,
    DropdownList,
    DropdownItemsContainer,
    DropdownItem,
    LoadingMessage,
    NoOptionsMessage,
} from "./dropdown-search.styles"; // Adjust path as needed

function DropdownSearch({
    placeholder = "Search...",
    width = "250px",
    height = "15px",
    maxItems = 50,
    field,
    onSelect = () => {},
    onClose = () => {},
    noOptionsMessage = "No options found",
    fetchData,
    reset,
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [query, setQuery] = useState("");
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [selectedItem, setSelectedItem] = useState(null); // Track the selected item
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);
    // const listRef = useRef(null);
    const [items, setItems] = useState([]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                inputRef.current &&
                !inputRef.current.contains(event.target)
            ) {
                if (isOpen) {
                setIsOpen(false);
                setIsLoading(true);
                onClose();
                }
                setSelectedIndex(-1);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) setIsLoading(true);
    }, [isOpen]);

    useEffect(() => {
        if (reset) setQuery("");
    }, [reset]);

    useEffect(() => {
        const populateData = async () => {
        if (!isOpen) return;
        const data = await fetchData(field, query);
        setItems(data);
        // Include the selected item in the dropdown if not in the fetched data
        if (selectedItem && !data.some((item) => item.id === selectedItem.id)) {
            setItems([selectedItem, ...data]);
        }
        setTimeout(() => setIsLoading(false), 500);
        };
        populateData();
    }, [isOpen, query]);

    const handleKeyDown = (e) => {
        if (!isOpen) {
        if (e.key === "ArrowDown" || e.key === "Enter") {
            setIsOpen(true);
        }
        return;
        }

        switch (e.key) {
        case "ArrowDown":
            e.preventDefault();
            setSelectedIndex((prev) => (prev < items.length - 1 ? prev + 1 : prev));
            break;
        case "ArrowUp":
            e.preventDefault();
            setSelectedIndex((prev) => (prev > 0 ? prev - 1 : prev));
            break;
        case "Enter":
            e.preventDefault();
            if (selectedIndex >= 0) {
            handleSelect(items[selectedIndex]);
            }
            break;
        case "Escape":
            setIsOpen(false);
            setSelectedIndex(-1);
            break;
        default:
            break;
        }
    };

    const handleSelect = (item) => {
        setQuery(item.value);
        setSelectedItem(item); // Store the selected item
        onSelect(item);
        setIsOpen(false);
        setSelectedIndex(-1);
    };

    const handleRemove = () => {
        setQuery("");
        setSelectedItem(null); // Clear the selected item
        setIsLoading(true);
        setIsOpen(true);
        setSelectedIndex(-1);
        onClose();
        inputRef.current.focus();
    };

  return (
    <DropdownContainer width={width} ref={dropdownRef}>
        <InputContainer>
            <SearchIcon>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm0-2a6 6 0 1 0 0-12 6 6 0 0 0 0 12zm11.707 5.707-5.073-5.073a8.03 8.03 0 0 1-1.414 1.414l5.073 5.073a1 1 0 0 0 1.414-1.414z" />
                </svg>
            </SearchIcon>
            <StyledInput
                type="text"
                placeholder={placeholder}
                value={query}
                height={height}
                ref={inputRef}
                onChange={(e) => {
                    setQuery(e.target.value);
                    setIsOpen(true);
                    setSelectedIndex(-1);
                    onClose();
                }}
                onClick={() => setIsOpen(true)}
                onKeyDown={handleKeyDown}
            />
            <CloseIcon onClick={handleRemove}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M6.225 4.811a1 1 0 0 0-1.414 1.414l5.292 5.293-5.292 5.293a1 1 0 0 0 1.414 1.414l5.293-5.292 5.293 5.292a1 1 0 0 0 1.414-1.414l-5.292-5.293 5.292-5.293a1 1 0 0 0-1.414-1.414l-5.293 5.292-5.293-5.292z" />
                </svg>
            </CloseIcon>
        </InputContainer>

        {isOpen && (
            <DropdownList>
                <DropdownItemsContainer
                    maxHeight={`${Number(height.replace("px", "")) * maxItems}px`}
                >
                    {isLoading ? (
                        <LoadingMessage>Loading...</LoadingMessage>
                    ) : items.length > 0 ? (
                        items.map((item, index) => (
                            <DropdownItem
                            key={`${item.id}-${index}`}
                            isSelected={selectedIndex === index}
                            onMouseDown={(e) => {
                                e.preventDefault();
                                handleSelect(item);
                            }}
                            onMouseEnter={() => setSelectedIndex(index)}
                            >
                            {item.value}
                            </DropdownItem>
                        ))
                    ) : (
                        <NoOptionsMessage>{noOptionsMessage}</NoOptionsMessage>
                    )}
                </DropdownItemsContainer>
            </DropdownList>
        )}
    </DropdownContainer>
  );
}

export default DropdownSearch;
