import styled, {css} from "styled-components";
import { FaCircle } from 'react-icons/fa'; // This is a filled circle that can act as a bullet


export const Card = styled.div`
    padding: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 550px;
    border-radius: 10px;
    height: fit-content;
    gap: 20px; 
    cursor: pointer;
    &:hover {
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        transition: box-shadow 0.2s;
    }
`;

// export const CardHeader = styled.div`
//     display: flex;
//     // justify-content: space-between;
//     align-items: center;
//     gap: 10px;
// `;

// export const PropertyImage = styled.img`
//     border-radius: 10px;
//     width: 80px;
//     height: 80px;
//     object-fit: cover;
// `;

// export const PropertyDetails = styled.div`
//     display: flex;
//     flex-direction: column;
//     gap: 5px;
// `;


// export const PropertyTitle = styled.h2`
//     font-size: 16px;
//     color: #323232;
//     margin: 0;
//     font-weight: 500;
// `;


// export const PropertyPrice = styled.div`
//     font-size: 12px;
//     color: ${props => props.priceUp ? '#1CCB00': props.priceDown ? '#FF0000':'var(--Grey, #747474)'};
//     font-style: normal;
//     font-weight: 500;
//     line-height: normal;
//     display: flex;
//     align-items: center;
//     gap: 3px;

//     @media screen and (max-width: 600px){
//         font-size: 12px;
//     }
// `;

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
`;

export const GridBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3px;
`;

export const GridTitle = styled.div`
    font-size: 12px;
    color: #a4a4a4;
`;

export const GridContent = styled.div`
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
    color: #323232;
`;

const badgeColors = {
    "Pending": `
        background-color: #ffd1d1; /* Light Red */
        color: #d8000c; /* Dark Red */
    `,
    "Pending Payments": `
        background-color: #fff4e5; /* Soft Amber */
        color: #ffa500; /* Amber */
    `,
    "Form Signing": `
        background-color: #e6f7ff; /* Light Blue */
        color: #007acc; /* Deep Blue */
    `,
    "Re-listed": `
        background-color: #f0f4ff; /* Soft Blue */
        color: #3366cc; /* Medium Blue */
    `,
    "Partially Accepted": `
        background-color: #fff4e5; /* Soft Amber */
        color: #ffa500; /* Amber */
    `,
    "Denied": `
        background-color: #ffebe6; /* Pale Red */
        color: #990000; /* Burgundy */
    `,
    "Awaiting Responses": `
        background-color: #FFFCE5; /* Pale Yellow */
        color: #CC9900; /* Golden Yellow */
    `,
    "Accepted": `
        background-color: #d4edda; /* Soft Green */
        color: #155724; /* Dark Green */
    `,
    "Renewed": `
        background-color: #e3ffe6; /* Completed Green */
        color: #1ccb00; /* Bright Green */
    `,
};




export const BulletIcon = styled(FaCircle)`
    font-size: 8px; // Size it to look like a bullet
    vertical-align: middle; // Align with the text
    margin-right: 4px; // Space after the bullet
`;

export const StatusBadge = styled.div`
    ${({ status }) => badgeColors[status] || ""}
    padding: 5px 10px;
    width: fit-content;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;


export const ActionButton = styled.button`
    background-color: #064274    ;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 12px;
    width: 100%;
    cursor: pointer;
    font-size: 14px;

    &:hover{ 
        background-color: #002456;
        transition: background-color 0.2s;
    }

`;


export const RemindButton = styled(ActionButton)`
    background-color: #F7F7F7;
    color: #323232;
    
    &:hover{ 
        background-color: #dedede80;
        transition: background-color 0.2s;
    }
`;

export const RelistButton = styled(ActionButton)`
    background-color: #E45A5C;
    color: #fff;
    
    &:hover{ 
        background-color: #B5373A; 
        transition: background-color 0.2s;
    }
`;
