import React, { useContext, useState } from "react";
import ReactDOM from 'react-dom';
import FormInput from "../form-input/form-input";
import PropertyInfo from "../property-info/property-info";
import moment from "moment";
import { toast } from "react-toastify";
import { ApiContext } from "../../context/apiContext";
import { ReactComponent as Document } from '../../assets/document-upload.svg'
import {
    ModalOverlay, ModalWrapper, Section, SectionTitle, SpaceDiv, FlexDiv, CloseButton, Title, WrapDiv, TextInput, Heading, PreviousButton, SubmitButton,
    UploadContainer, FileUploadLabel, HiddenFileInput, RoundDiv, UploadInstruction, RemoveButton, AttachmentBox
} from './renewal-offer-modality.styles'

const RenewalOffer = ({renewalId, renewalProperty, onClose, addOffer, offerPrice, firstOffer = false}) => {
    const api = useContext(ApiContext);
    const [errors, setErrors] = useState({});
    const [previewUrl, setPreviewUrl] = useState(null)
    const [offer, setOffer] = useState({
        title: "",
        description: "",
        updateRent: offerPrice,
        validityDate: "",
        attachment: null,
    })


    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setOffer(prev => ({
            ...prev,
            attachment: event.target.files[0]
        }))
        if (file && file.type.substr(0, 5) === 'image') {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };


    const onRemove = () => {
        setPreviewUrl(null)
        setOffer(prev => ({...prev, attachment: null}))
    }

    const updateOffer = (key, value) => {
        setOffer(prev => ({
            ...prev,
            [key]: value
        }))
    }

    const validateInputs = () => {
        const newErrors = {};
        if (!offer.title.trim()) newErrors.title = "Title is required.";
        if (!offer.updateRent || isNaN(offer.updateRent) || parseFloat(offer.updateRent) <= 0)
            newErrors.updateRent = "New Rent is required.";
        if (!offer.validityDate || !moment(offer.validityDate, "YYYY-MM-DD", true).isValid())
            newErrors.validityDate = "Deadline is required.";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if no errors
    };

    const submitOffer = async (event) => {
        event.preventDefault();
        if(validateInputs()){
            try{
                const formData = new FormData();
                formData.append("renewalId", renewalId);
                formData.append("title", offer.title);
                formData.append("description", offer.description);
                formData.append("updateRent", offer.updateRent);
                formData.append("validityDate", offer.validityDate);
                if (offer.attachment) formData.append("attachment", offer.attachment);
                if(firstOffer) formData.append("deadline", offer.validityDate);

                const endpoint = firstOffer ? "/api/renewals/request" : "/api/renewals/offer";
                const data = await api.post(endpoint, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                addOffer(data);
                onClose();
            }catch(error){
                toast.error("Error submitting offer:", error);
            }
        }
    }

    return ReactDOM.createPortal(
        <ModalOverlay onClick={onClose}>
            <ModalWrapper
                onClick={(e) => e.stopPropagation()}
            >
                <SpaceDiv>
                    <Title>Renewal Offer</Title>
                    <FlexDiv>
                        <span style={{fontSize:"14px"}}>Close</span>
                        <CloseButton onClick={onClose}>X</CloseButton>
                    </FlexDiv>
                </SpaceDiv>
                <Section>
                    {/* <SectionTitle>Property</SectionTitle> */}
                    <PropertyInfo
                        property={renewalProperty}
                        offerPrice={offerPrice || renewalProperty.price}
                    />
                </Section>
                <Section>
                    {/* <SectionTitle>Offer Information</SectionTitle> */}
                    <WrapDiv>
                        <FormInput
                            label = "Offer Title"
                            type = "text"
                            isRequired={true}
                            flexBasis={true}
                            handleChange={(value) => updateOffer("title", value)}
                            error={errors.title}
                        /> 
                    </WrapDiv>
                    <WrapDiv>
                        <FormInput
                            label = "New Rent"
                            type = "number"
                            isRequired={true}
                            flexBasis={true}
                            defaultValue={offer.updateRent}
                            handleChange={(value) => updateOffer("updateRent", value)}
                            error={errors.updateRent}
                        /> 
                        <FormInput
                            label = "Revert Deadline"
                            type = "date"
                            isRequired={true}
                            flexBasis={true}
                            defaultValue={
                                offer?.validityDate? moment.utc(new Date(offer.validityDate)).format("YYYY-MM-DD"): ""
                            }
                            handleChange={(value) => updateOffer( "validityDate", moment(value).format("YYYY-MM-DD"))}
                            error={errors.validityDate}
                        />    
                    </WrapDiv>
                </Section>
                <Section style={{gap:"5px"}}>
                    <Heading>Offer Description</Heading>
                    <TextInput
                        rows={3}
                        value={offer.description}
                        onChange={(event) => updateOffer("description", event.target.value)}
                    />
                </Section>
                <Section style={{gap:"5px"}}>
                    <Heading>Attachment (if any)</Heading>
                    <UploadContainer>
                        {
                            previewUrl ?
                            <AttachmentBox>
                                <img src = {previewUrl}/>
                                <RemoveButton onClick={onRemove}>×</RemoveButton>
                            </AttachmentBox>
                            :
                            <FileUploadLabel htmlFor="file-upload">
                                <RoundDiv><Document /></RoundDiv>
                                <HiddenFileInput 
                                    id="file-upload" 
                                    type="file" 
                                    accept="image/*" 
                                    onChange={handleFileUpload} 
                                />
                                <UploadInstruction>Click to Upload<br/>(Max. File size: 5 MB)</UploadInstruction>
                            </FileUploadLabel>
                        }
                    </UploadContainer>
                </Section>
                <SubmitButton onClick={submitOffer}>{ firstOffer ?"Send Renewal Request":"Submit Offer"}</SubmitButton>
            </ModalWrapper>
        </ModalOverlay>
        ,
        document.getElementById('modal-root')
    )
}

export default RenewalOffer