import React, {useRef, useState, useCallback} from "react";
import FormInput from "../form-input/form-input";
import ReactDOM from 'react-dom';
import {useNavigate} from 'react-router'
import moment from "moment";
import {
    ModalBackdrop, ModalContainer, ModalHeader, ConfirmButton, CancelButton, ModalFooter, WrapDiv, Currency, Label, TableContainer, TableRow, TableCell
} from './new-application-modality.styles'


const NewAppModality = ({applicationData, newProperty, isOpen, onClose, otherApplicationState, setOtherApplicationState}) => {
    const navigate = useNavigate();
    const modalRef = useRef();
    const currentDate = moment().format('yyyy-MM-DD');
    const [newApplication, setNewApplication] = useState({ 
        applicants: applicationData?.applicants,
        moveinDeposits: {
            ...newProperty?.moveinDeposits,
            "Deposit On Account": applicationData?.offerMoveinDeposits['Deposit On Account'] || 0
        },
        requestedMoveIn: applicationData?.requestedMoveIn,
        requestedMoveOut: applicationData?.requestedMoveOut,
        offerPrice: newProperty?.price
    });
    const [errors, setErrors] = useState({});

    // Handle change with useCallback to prevent unnecessary re-renders
    const handleChange = (name, value) => {
        console.log(name, value)
        setNewApplication(prev => ({ ...prev, [name]: value }));
    };

    const handleDepositChange =(key, value) => {
        // console.log(key, value)
        setNewApplication(prev => ({
            ...prev,
            moveinDeposits: {
                ...prev.moveinDeposits,
                [key]: Number(value)
            }
        }));
    };

    const handleDateChange = (key,val) => {
        handleChange(key,moment(val).format('YYYY-MM-DD'))
    }

    const validateInputs = () => {
        let newErrors = {};
        if (!applicationData.requestedMoveIn){
            newErrors.requestedMoveIn = 'Move in date is required';
        }else if (applicationData.requestedMoveIn < new Date()){
            newErrors.requestedMoveIn = 'Move in date cannot be earlier than today\'s date';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const onSubmit = async () => {
        if (!validateInputs()) return;
        const payload = {
            applicationId: applicationData.id,
            propertyId: newProperty.id,
            ...newApplication
        }
        try {
            const endpoint = otherApplicationState === "Switch" ? '/api/application/switch' : '/api/application/reutilize';
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload), // Convert the JavaScript object to a JSON string
            });
                
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }      
            const data = await response.json();
            onClose()
            setOtherApplicationState()
            navigate(`/application-status/${data.id}`)
            // setApplicationData(prev => ({
            //     ...prev,
            //     ...data
            // }))

        } catch (error){
            alert('There was an issue submitting your request. Please email us at support@avaasa.us for assistance.');
        }
    }

    const renderDeposits = () => {
        return Object.entries(newApplication?.moveinDeposits || {})
            .map(([key, value]) => (
                <Currency key={key} style={{ flex: '1' }}>
                    {key === "Application Fee" ? 'Application Fee/Person' : key}
                    <FormInput
                        type='currency'
                        defaultValue={value}
                        value={value}
                        flexBasis
                        handleChange={(val) => handleDepositChange(key, val)}
                    />
                </Currency>
            ));
    }


    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <ModalBackdrop onClick={onClose}>
            <ModalContainer ref={modalRef} onClick={(e) => e.stopPropagation()}>
                <ModalHeader>{otherApplicationState ==="Switch"? "Switch":"New"} Application- {newProperty?.address} </ModalHeader>
                {
                    otherApplicationState ==="Switch"?
                    <div>
                        You are switching the application. The current application will be withdrawn and a new application will be created in its place.
                    </div>
                    :
                    <div>
                        You are creating a new application. The current application documents will be directly used for the new application. 
                        Note: It is not possible to add, change or remove applicants.
                    </div>
                }
                <Label>Applicants</Label>
                <TableContainer>
                    <TableRow header={true}>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Type</TableCell>
                    </TableRow>
                    {
                        applicationData.applicants.map(applicant => (
                            <TableRow key={applicant.id}>
                                <TableCell>{applicant.name}</TableCell>
                                <TableCell>{applicant.email}</TableCell>
                                <TableCell>{applicant.applicantType}</TableCell>
                            </TableRow>
                        ))
                    }
                </TableContainer>
                <Label>Lease Terms</Label>
                <WrapDiv>
                    <Currency style = {{flex:'1'}}>Lease Start:
                        <FormInput
                            id="requestedMoveIn"
                            type="date"
                            defaultValue={moment(newApplication.requestedMoveIn).format('yyyy-MM-DD')}
                            handleChange={(value) => handleDateChange('requestedMoveIn', value)}
                            flexBasis={true}
                            error = {errors.requestedMoveIn}
                            isRequired={true}
                            minDate={currentDate}
                        />
                    </Currency>
                    <Currency style = {{flex:'1'}}>Lease End:
                        <FormInput
                            id="requestedMoveOut"
                            type="date"
                            defaultValue={moment(newApplication.requestedMoveOut).format('yyyy-MM-DD')}
                            min={new Date()}
                            handleChange={(value) => handleDateChange('requestedMoveOut', value)}
                            flexBasis={true}
                            error = {errors.requestedMoveOut}
                            isRequired={true}
                            minDate={currentDate}
                        />
                    </Currency>
                </WrapDiv>
                <Label>Offer & Deposits</Label>
                <WrapDiv>
                    <Currency style = {{flex:'1'}}>
                        Base Rent
                        <FormInput
                            isRequired={false}
                            type={'currency'}
                            defaultValue={newApplication?.offerPrice}
                            flexBasis={true}
                            handleChange={(val) => handleChange("offerPrice", val)}
                        />
                    </Currency>
                    {renderDeposits()}
                </WrapDiv>
                <ModalFooter>
                    <CancelButton onClick={onClose}>Cancel</CancelButton>
                    <ConfirmButton onClick={onSubmit}>Submit</ConfirmButton>
                </ModalFooter>
            </ModalContainer>
        </ModalBackdrop>,
        document.getElementById('modal-root')
    );
}

export default NewAppModality